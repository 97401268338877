import React, { Component, Fragment } from 'react'
import { Switch } from '@headlessui/react'
import { getSudomain, setTitle, getHeaders } from './../../helpers'
import Cookies from 'js-cookie';
import { Dialog, Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, ExclamationIcon, XIcon } from '@heroicons/react/outline'
import Calendar from 'rc-year-calendar';
// import 'rc-year-calendar/dist/rc-year-calendar.css';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const currentYear = new Date().getFullYear();
const allMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

export default class SubPolicy extends Component {
  constructor(){
    super();
    this.state = {
      today: new Date(),
      currentMonth: this.checkCurentMonth( new Date() ),
      subPolicy: false,
      allowCertainDate: false,
      enableRequestSendingTimeCheck: false,
      additionalMonths: 0,
      additionalMonthsName: this.checkMonthNameAfterAddition( new Date(), 0 ),
      certainDate: 1,
      certainDateAdditionalMonth: 0,
      certainDateAdditionalMonthName: this.checkMonthNameAfterCertainAddition( new Date(), 0, 0 ),
      timeBeforeClass: 1,
      timeBeforeClassType: 'days',
      alert_message: '',
      alert_type: '',
      show: false,
      loaded: false,
      enableBlackoutDates: false,
      blackoutDates: [],
      selectedYear: parseInt(currentYear),
      possibleYears: [parseInt(currentYear)-1, parseInt(currentYear), parseInt(currentYear)+1]
    }
  }

  checkCurentMonth = (today) => {
    return today.toLocaleString('default', { month: 'long' })
  }

  checkMonthNameAfterAddition = (today, value) => {
    let dateCopy = new Date(today.getTime());
    let currentMonthName = dateCopy.toLocaleString('default', { month: 'long' })
    let all_months = allMonths
    let indexOfCurrentMonth = all_months.indexOf(currentMonthName)
    let data = (indexOfCurrentMonth + 1) + parseInt(value)
    if (data > all_months.length){
      data = data - (all_months.length)
    }
    let index = 0
    if( data > 0){
      index = data - 1
    }
    return all_months[index]
  }

  checkMonthNameAfterCertainAddition = (today, value, prevAdditionalMonth) => {
    let dateCopy = new Date(today.getTime());
    let currentMonthName = dateCopy.toLocaleString('default', { month: 'long' })
    let all_months = allMonths
    let indexOfCurrentMonth = all_months.indexOf(currentMonthName)
    let data = (indexOfCurrentMonth + 1) + parseInt(value) + parseInt(prevAdditionalMonth)
    if (data > all_months.length){
      data = data - (all_months.length)
    }
    let index = 0
    if( data > 0){
      index = data - 1
    }
    return all_months[index]
  }

  componentDidMount = () => {
    setTitle('account_info')
    this.getSubPolicyData()
  }

  getSubPolicyData = () => {
    let url = '/api/v3/sub_policy_data'
    const requestOptions = {
      headers: getHeaders(Cookies.get(`currentUser.${getSudomain()}`), getSudomain())
    };
    fetch(url, requestOptions)
    .then(response => response.json())
    .then(result => {
      if(result.status === 200){
        this.setState({
          subPolicy: result?.sub_policy?.sub_policy,
          additionalMonths: result?.sub_policy?.additional_months,
          additionalMonthsName: this.checkMonthNameAfterAddition( new Date(), result?.sub_policy?.additional_months ),
          allowCertainDate: result?.sub_policy?.allow_certain_date,
          certainDate: result?.sub_policy?.certain_date,
          certainDateAdditionalMonth: result?.sub_policy?.certain_date_additional_month,
          certainDateAdditionalMonthName: this.checkMonthNameAfterCertainAddition( new Date(), result?.sub_policy?.certain_date_additional_month, result?.sub_policy?.additional_months ),
          enableRequestSendingTimeCheck: result?.sub_policy?.enable_request_sending_time_check,
          timeBeforeClass: result?.sub_policy?.time_before_class,
          timeBeforeClassType: result?.sub_policy?.time_before_class_type,
          blackoutDates: result?.sub_policy?.blackout_dates.map((item, i) => {return { 'id': i, 'startDate': new Date(item), 'endDate': new Date(item), color: 'yellow' }}),
          enableBlackoutDates: result?.sub_policy?.blackout_date_check,
          loaded: true
        })
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true
        })
      }
    })
  }

  componentWillUnmount() {
    this.setState = (state,callback)=>{
      return;
    };
  }

  handleSwitch = (e, name) => {
    this.setState({
      [name]: !this.state?.[name]
    })
    // if(name === "subPolicy" && e === false){
    //   this.setState({enableBlackoutDates: false})
    // }  
  }

  handleChange = (e) => {
    const { name, value } = e.target

    if(name === 'additionalMonths'){
      let val = 0//value === '' ? 0 : value
      if(value !== ''){
        if(value > 5){
          return
        }else{
          let result = value.replace(/^0+/, '');
          val = result// == '' ? 0 : result
        }
      }else{
        val = 0
      }

      this.setState({
        additionalMonths: val
      })
      let certainDateAdditionalMonthVal = this.state.certainDateAdditionalMonth === '' ? 0 : this.state.certainDateAdditionalMonth

      let today = this.state.today

      let dateCopy = new Date(today.getTime());
      let dateSecCopy = new Date(today.getTime());

      let addedMonth = this.checkMonthNameAfterAddition(dateCopy, parseInt(val == '' ? 0 : val))

      let certainDateAdditionalMonth = this.checkMonthNameAfterCertainAddition (dateSecCopy, parseInt(val == '' ? 0 : val), parseInt(certainDateAdditionalMonthVal))
      this.setState({
        additionalMonthsName: addedMonth,
        certainDateAdditionalMonthName: certainDateAdditionalMonth
      })
    }else if(name === 'certainDate'){
      let val = ''
      if(value !== ''){
        if(value > 30 || value < 1){
          return
        }else{
          let result = value.replace(/^0+/, '');
          val = result// == '' ? 0 : result
        }
      }else{
        // val = 1
      }
      this.setState({
        certainDate: val
      })
    }else if(name === 'certainDateAdditionalMonth'){
      let val = 0//value === '' ? 0 : value
      if(value !== ''){
        if(value > 3){
          return
        }else{
          let result = value.replace(/^0+/, '');
          val = result
        }
      }else{
        val = 0
      }
      this.setState({
        certainDateAdditionalMonth: val
      })
      let addedMonthVal = this.state.additionalMonths === '' ? 0 : this.state.additionalMonths
      let today = this.state.today
      let dateCopy = new Date(today.getTime());
      let addedMonth = this.checkMonthNameAfterCertainAddition (dateCopy, parseInt(val == '' ? 0 : val), parseInt(addedMonthVal))
      this.setState({certainDateAdditionalMonthName: addedMonth})
    }else if(name === 'timeBeforeClass'){
      let val = 0
      if(value !== ''){
        // timeBeforeClassType === 'hours' ? 24 : 30
        if(this.state.timeBeforeClassType === 'days' && value > 30 || this.state.timeBeforeClassType === 'hours' && value > 24){
          return
        }else{
          let result = value.replace(/^0+/, '');
          val = result// == '' ? 0 : result
        }
      }else{
        val = 0
      }
      this.setState({
        timeBeforeClass: val
      })
    }else{
      this.setState({
        [name]: value
      })
    }
  }

  handleReminderDayKeyDown = (e) => {
    var invalidChars = [
      "-",
      "+",
      "e",
      "."
    ]
    if(invalidChars.includes(e.key)){
      e.preventDefault()
    }
  }

  handleAdditionalMonthOnBlur = (e) => {
    const { name, value } = e.target
    let val = 0
    if(value === ''){
      val = 0
      if(name === 'additionalMonths'){
  
        this.setState({
          additionalMonths: val
        })
        let certainDateAdditionalMonthVal = this.state.certainDateAdditionalMonth === '' ? 0 : this.state.certainDateAdditionalMonth
  
        let today = this.state.today
  
        let dateCopy = new Date(today.getTime());
        let dateSecCopy = new Date(today.getTime());
  
        let addedMonth = this.checkMonthNameAfterAddition(dateCopy, parseInt(val))
        let certainDateAdditionalMonth = this.checkMonthNameAfterCertainAddition (dateSecCopy, parseInt(val), parseInt(certainDateAdditionalMonthVal))
        this.setState({
          additionalMonthsName: addedMonth,
          certainDateAdditionalMonthName: certainDateAdditionalMonth
        })
      }else if(name === 'certainDateAdditionalMonth'){
        this.setState({
          certainDateAdditionalMonth: val
        })
        let addedMonthVal = this.state.additionalMonths === '' ? 0 : this.state.additionalMonths
        let today = this.state.today
        let dateCopy = new Date(today.getTime());
        let addedMonth =  this.checkMonthNameAfterCertainAddition (dateCopy, parseInt(val), parseInt(addedMonthVal))
        this.setState({certainDateAdditionalMonthName: addedMonth})
      }else if(name === 'certainDate'){
        this.setState({
          certainDate: 1
        })
      }else if(name === 'timeBeforeClass'){
        this.setState({
          timeBeforeClass: val
        })
      }
    }
  }

  handleUpdateSubPolicy = (e) => {
    const { subPolicy, additionalMonths, allowCertainDate, certainDate, certainDateAdditionalMonth, enableRequestSendingTimeCheck, timeBeforeClass, timeBeforeClassType, blackoutDates, enableBlackoutDates } = this.state

    let datesBlackout = blackoutDates.map((item) => item?.startDate.getFullYear().toString()+'-'+(item?.startDate.getMonth()+1).toString()+'-'+item?.startDate.getDate().toString())

    let sub_policy = {
      additional_months: additionalMonths,
      allow_certain_date: allowCertainDate,
      certain_date: certainDate,
      certain_date_additional_month: certainDateAdditionalMonth,
      enable_request_sending_time_check: enableRequestSendingTimeCheck,
      sub_policy: subPolicy,
      time_before_class: timeBeforeClass,
      time_before_class_type: timeBeforeClassType,
      blackout_dates: datesBlackout,
      blackout_date_check: enableBlackoutDates
    }

    let url = '/api/v3/sub_policy_update'
    const requestOptions = {
      method: 'PATCH',
      headers: getHeaders(Cookies.get(`currentUser.${getSudomain()}`), getSudomain()),
      body: JSON.stringify({ sub_policy })
    };
    fetch(url, requestOptions)
    .then(response => response.json())
    .then(result => {
      if(result.status === 200){
        this.setState({
          alert_message: result.message,
          alert_type: 'success',
          show: true,
        })
        setTimeout(function(){ window.location.reload() }, 1500);
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true
        })
        setTimeout(function(){ window.location.reload() }, 3000);
      }
    })
  }

  saveCurrentEventOnClick(e) {
    if(e.events !== undefined && e.events?.length > 0){
      // Delete event

      let evt = e.events[0]
      this.setState({ blackoutDates: this.state.blackoutDates.filter(item => item.id != evt.id) })
    }else{
      // Add event

      let id = 0 
      if(this.state.blackoutDates?.length > 0){
        id = Math.max(...this.state.blackoutDates.map(evt => evt.id)) + 1;
      }
      let currentEvent = { 'id': id, 'startDate': e.date, 'endDate': e.date, color: 'yellow' }
      this.setState({ blackoutDates: this.state.blackoutDates.concat([currentEvent]) });
    }
  }

  render() {
    const { subPolicy, allowCertainDate, enableRequestSendingTimeCheck, additionalMonths, additionalMonthsName, certainDate, certainDateAdditionalMonth, certainDateAdditionalMonthName, timeBeforeClass, timeBeforeClassType, today, currentMonth, alert_message, alert_type, show, loaded, enableBlackoutDates, blackoutDates, selectedYear, possibleYears } = this.state
    const { user, business, loadingMessage } = this.props
    
    return (
      <React.Fragment>
        {show &&
          <React.Fragment>
            {alert_type === 'success' &&
              <div className="w-3/5 flex items-center bg-green-50 rounded-md px-4 py-3.5 mb-1 fixed top-1 z-50 left-0 right-0 mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-400 mr-3.5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
                <div className="text-sm text-green-800 font-medium">
                  {alert_message}
                </div>
                <a className="ml-auto">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </a>
              </div>
            }
            {alert_type === 'danger' &&
              <div className="w-3/5 flex items-center bg-red-100 rounded-md px-4 py-3.5 mb-1 fixed top-1 z-50 left-0 right-0 mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-red-300 mr-3.5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                </svg>
                <div className="text-sm text-red-700 font-medium">
                  {alert_message}
                </div>
                <a className="ml-auto">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </a>
              </div>
            }  
          </React.Fragment>
        }
        {!loaded && 
          <div className="msg-loading flex flex-col items-center w-full justify-center h-screen">
            <p className="mb-1 text-center px-5">{loadingMessage}</p>
            <img className = "w-9" src="/assets/business/loading.gif" />
          </div>
        }
        {loaded &&
          <div className='bg-white rounded-md shadow mb-10 px-6 py-10'>
            <div className='flex items-center pb-3 gap-3'>
              <div className='text-sm'>Do you want to enable a sub policy?</div>
              <div>
                <Switch
                  id={`subPolicy-1`}
                  checked={subPolicy}
                  name="subPolicy"
                  onChange={(e) => this.handleSwitch (e, "subPolicy")}
                  className={classNames(
                    subPolicy ? 'bg-gray-900' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
                  )}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      subPolicy ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
                <label className='text-sm ml-2' htmlFor="subPolicy-1">
                  {subPolicy ? 'Yes' : 'No'}
                </label>
              </div>
            </div>

            {subPolicy &&
              <React.Fragment>
                <div className='flex items-center pb-3 gap-3'>
                  <div className='text-sm'>Always allow sub requests for current month and  </div>
                  <input type="number" className='shadow-sm border focus:ring-indigo-500 focus:border-indigo-500 block w-20 h-9 sm:text-sm border-gray-300 rounded-md text-center' name='additionalMonths' value={additionalMonths} min={0} max={5} onChange={(e) => this.handleChange(e)} onKeyDown={(e) => this.handleReminderDayKeyDown(e)} onBlur={(e) => this.handleAdditionalMonthOnBlur(e)}/>
                  <div className='text-sm'>additional month(s)</div>
                </div>
                
                <div className='flex items-center pb-3 gap-3'>
                  <div className='text-sm'>Do you want to release future months on a certain date within the current month?</div>
                  <div>
                    <Switch
                      id={`allowCertainDate-1`}
                      checked={allowCertainDate}
                      name="allowCertainDate"
                      onChange={(e) => this.handleSwitch (e, "allowCertainDate")}
                      className={classNames(
                        allowCertainDate ? 'bg-gray-900' : 'bg-gray-200',
                        'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
                      )}
                    >
                      <span className="sr-only">Use setting</span>
                      <span
                        aria-hidden="true"
                        className={classNames(
                          allowCertainDate ? 'translate-x-5' : 'translate-x-0',
                          'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                        )}
                      />
                    </Switch>
                    <label className='text-sm ml-2' htmlFor="allowCertainDate-1">
                      {allowCertainDate ? 'Yes' : 'No'}
                    </label>
                  </div>
                </div>

                {allowCertainDate &&
                  <div className="flex items-center pb-3 gap-3">
                    <div className='text-sm'>On day</div>
                    <input type="number" className='shadow-sm border focus:ring-indigo-500 focus:border-indigo-500 block w-20 h-9 sm:text-sm border-gray-300 rounded-md text-center' name='certainDate' value={certainDate} min={1} max={30} onChange={(e) => this.handleChange(e)} onKeyDown={(e) => this.handleReminderDayKeyDown(e)} onBlur={(e) => this.handleAdditionalMonthOnBlur(e)}/>
                    <div className='text-sm'>of current month, allow sub request for</div>
                    <input type="number" className='shadow-sm border focus:ring-indigo-500 focus:border-indigo-500 block w-20 h-9 sm:text-sm border-gray-300 rounded-md text-center' name='certainDateAdditionalMonth' value={certainDateAdditionalMonth} min={0} max={3} onChange={(e) => this.handleChange(e)} onKeyDown={(e) => this.handleReminderDayKeyDown(e)} onBlur={(e) => this.handleAdditionalMonthOnBlur(e)}/>
                    <div className='text-sm'>additional month(s)</div>
                  </div>
                }

                <div className='flex items-center pb-3 gap-3'>
                  <div className='text-sm'>Do you want to prevent instructors from sending sub request with in a certain amount of time before the class?</div>
                  <div>
                    <Switch
                      id={`enableRequestSendingTimeCheck-1`}
                      checked={enableRequestSendingTimeCheck}
                      name="enableRequestSendingTimeCheck"
                      onChange={(e) => this.handleSwitch (e, "enableRequestSendingTimeCheck")}
                      className={classNames(
                        enableRequestSendingTimeCheck ? 'bg-gray-900' : 'bg-gray-200',
                        'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
                      )}
                    >
                      <span className="sr-only">Use setting</span>
                      <span
                        aria-hidden="true"
                        className={classNames(
                          enableRequestSendingTimeCheck ? 'translate-x-5' : 'translate-x-0',
                          'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                        )}
                      />
                    </Switch>
                    <label className='text-sm ml-2' htmlFor="enableRequestSendingTimeCheck-1">
                      {enableRequestSendingTimeCheck ? 'Yes' : 'No'}
                    </label>
                  </div>
                </div>

                {enableRequestSendingTimeCheck &&
                  <div className="flex items-center pb-3 gap-3">
                    <div className='text-sm'>Instructors cannot send sub requests within</div>
                    <input type="number" className='shadow-sm border focus:ring-indigo-500 focus:border-indigo-500 block w-20 h-9 sm:text-sm border-gray-300 rounded-md text-center' name='timeBeforeClass' value={timeBeforeClass} min={1} max={timeBeforeClassType === 'hours' ? 24 : 30} onChange={(e) => this.handleChange(e)} onKeyDown={(e) => this.handleReminderDayKeyDown(e)} onBlur={(e) => this.handleAdditionalMonthOnBlur(e)}/>
                    <select name="timeBeforeClassType" defaultValue={timeBeforeClassType} onChange={(e) => this.handleChange(e)} className='shadow-sm border focus:ring-indigo-500 focus:border-indigo-500 block w-20 h-9 sm:text-sm border-gray-300 rounded-md'>
                      <option value="hours">Hours</option>
                      <option value='days'>Days</option>
                    </select> 
                    <div className='text-sm'>of the class start time</div>
                    
                  </div>
                }

                <p className='text-xs pt-4 pb-5'>{`Here's summary of your policy: Always allow sub requests for current month (${currentMonth}) and ${additionalMonths === '' ? 0 : additionalMonths} additional month${additionalMonths > 1 ? 's' : ''}${additionalMonthsName === '' ? '' : ` (${additionalMonthsName})`}.${allowCertainDate ? ` On the ${certainDate === '' ? '1' : certainDate}th on the current month (${currentMonth}), instructors can begin sending sub requests for ${certainDateAdditionalMonth === '' ? 0 : certainDateAdditionalMonth} more additional month${certainDateAdditionalMonth > 1 ? 's' : ''}${certainDateAdditionalMonthName === '' ? '' : ` (${certainDateAdditionalMonthName})`}.`:''}${enableRequestSendingTimeCheck ? ` Instructors cannot send sub requests within ${timeBeforeClass === '' ? '0' : timeBeforeClass} ${timeBeforeClassType === 'hours' ? `${timeBeforeClass > 1 ? timeBeforeClassType : 'hour'}` : `${timeBeforeClass > 1 ? timeBeforeClassType : 'day'}`} of the start time of the class.`:''}`}  </p>
                
              </React.Fragment>
            }

            <div className='flex items-center pb-3 gap-3'>
              <div className='text-sm'>Do you want to enable blackout dates?</div>
              <div className=''>
                <Switch
                  id={`enableBlackoutDates-1`}
                  checked={enableBlackoutDates}
                  name="enableBlackoutDates"
                  onChange={(e) => this.handleSwitch (e, "enableBlackoutDates")}
                  className={classNames(
                    enableBlackoutDates ? 'bg-gray-900' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
                  )}
                  // disabled={!subPolicy}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      enableBlackoutDates ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
                <label className='text-sm ml-2' htmlFor="enableBlackoutDates-1">
                  {enableBlackoutDates ? 'Yes' : 'No'}
                </label>
              </div>
            </div>

            {enableBlackoutDates &&
              <div className='items-center pb-3 gap-3'>
                <div className='text-sm'>
                  Please select specific dates in which you do not want instructors to send out sub requests.
                </div>
                <div className='mt-5'>
                  <div className='flex justify-between items-center'>
                    <div className='ml-2 text-gray-900 font-medium'>
                      {selectedYear}
                    </div>
                    <div className='mr-2'>
                      <div className="relative filters_box w-full lg:w-36">
                        <Menu as="div" className="relative block text-left">
                          <div>
                            <Menu.Button className="h-10 flex justify-center w-full rounded border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                              Year View
                              <ChevronDownIcon className="ml-auto text-gray-400 w-4 mr-0.5 mt-0.5" aria-hidden="true" />
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95">
                            <Menu.Items className="origin-top-right z-10 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <div className="max-h-60 overflow-y-auto">
                                <div className="py-1">
                                  {possibleYears.map((year, i) => 
                                    <div key={i} className={`${selectedYear == year ? 'bg-gray-200' : ''} hover:bg-gray-100`}>
                                      <Menu.Item>
                                        {({ active }) => (
                                          <a className={`p-2 flex items-center text-gray-900 text-sm cursor-pointer`} onClick={(e) => this.setState({selectedYear: year})}>
                                            {year}
                                          </a> 
                                        )}
                                      </Menu.Item>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                    </div>
                  </div>
                  <Calendar 
                    style="background"
                    enableRangeSelection={false}
                    enableContextMenu={false}
                    year={selectedYear}
                    onDayClick={(e) => this.saveCurrentEventOnClick(e)}
                    dataSource={blackoutDates}
                  />
                </div>
              </div>
            }

            <div className='mt-4 mr-4 flex justify-center'>
              <a className="bg-dark-blue text-white inline-block px-4 py-2 ml-auto border shadow rounded-md cursor-pointer" onClick={(e) => this.handleUpdateSubPolicy(e)}>
                Update Sub Policy
              </a>
            </div>
          </div>
        }
      </React.Fragment>
    )
  }
}
