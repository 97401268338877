import React, { Fragment, useEffect, useState } from 'react';
import { axiosGetRequest } from './../../../helpers/helpers'
import LoaderComponent from './../loaderComponent/loaderComponent'
import CustomPopupComponent from './../customPopupComponent/customPopupComponent';
import EventDetailsComponent from './../eventDetailsComponent/eventDetailsComponent'
import AlertPopup from './../../../../messenger/pages/chatComponents/assets/alertPopup'

export default function ShowEventDeatilsComponent(props){
  const { eventId, show, close, business, user, processGetEventData, path, myRequestsTab, dateToSave, handleUpdateCalendarEvent } = props
  const [ loaded, setLoaded ] = useState(false)
  const [ eventData, setEvenData ] = useState({})
  const [ showAlert, setShowAlert ] = useState(false)
  const [ alertMessage, setAlertMessage ] = useState('')
  const [ alertType, setAlertType ] = useState('')

  useEffect(() => {
    if(eventId && eventId !== ''){
      processFetchEventData()
    }
  }, [eventId])

  const processFetchEventData = () => {
    let bucketType = ['my-requests'].includes(path) ? myRequestsTab : ''
    let url = `/api/v3/business_events/${parseInt(eventId)}?preview=true&bucket_type=${bucketType}`
    axiosGetRequest(url).then((response) => {
      if(response?.status === 200){
        setEvenData(response?.event)
        setLoaded(true)
      }else{
        close(false)
      }
    })
  }

  const handleAlert = (show, message, type) => {
    setShowAlert(show)
    setAlertMessage(message)
    setAlertType(type)
  }

  const closeAlert = (data) => {
    setShowAlert(data)
    setAlertMessage('')
    setAlertType('')
  }

  const handleCloseAndRefetchEvents = () => {
    close(false)
    // processGetEventData(false)
  }

  const handleUpdateEvent = (data) => {
    setEvenData(data)
  }

  return(
    <Fragment>
      {showAlert &&
        <AlertPopup alert_type={alertType} alert_message={alertMessage} closeAlert={closeAlert} />
      }
      <CustomPopupComponent show={show} close={close} title={''}>
        {!loaded &&
          <Fragment>
            <div className='flex flex-col items-center w-full justify-center h-80'>
              <img className = "w-9" src="/assets/business/loading.gif" />
            </div>
          </Fragment>
        }
        {loaded &&
          <Fragment>
            <EventDetailsComponent eventData={eventData} eventId={eventId} business={business} user={user} handleAlert={handleAlert} processFetchEventData={processFetchEventData} closeAndReloadData={handleCloseAndRefetchEvents} handleUpdateEvent={handleUpdateEvent} path={path} myRequestsTab={myRequestsTab} dateToSave={dateToSave} handleUpdateCalendarEvent={handleUpdateCalendarEvent}/>
          </Fragment>
        }
      </CustomPopupComponent>
      
    </Fragment>
  )
}