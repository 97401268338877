import React, { Component } from 'react'
import ConfigureNavbar from './navbar'
import MboDetails from './details/mbo'
import NetgymSettings from './netgymSettings'
import AbcDetails from './details/abc'
import MarianaDetails from './details/mariana'
import CrunchDetails from './details/crunch'
import SubPolicy from './subRequestPolicy'
import { snakeCase } from './../helpers'
import FisikalDetails from './details/fisikal'
import ClubReadyDetails from './details/clubReady'

export default class ConfigureLayout extends Component {

  componentDidMount(){
    if(Object.keys(this.props?.user).length !== 0){
      if (this.props?.user?.role_display !== 'Netgym Employee'){
        location.href = `/business_user#/${snakeCase(this.props?.user?.role)}_home`
      }
    }
    this.props.setCurrent('Configure')
  }

  renderSwitch(){    
    switch(this.props.path){
      case 'netgym-settings':
        return <NetgymSettings user={this.props.user} business={this.props.business} permissions = {this.props.permissions} loadingMessage = {this.props.loadingMessage} pageLoaded={this.props.pageLoaded}/>
      case 'mbo-details':
        return <MboDetails user={this.props.user} business={this.props.business} permissions = {this.props.permissions} loadingMessage = {this.props.loadingMessage} pageLoaded={this.props.pageLoaded}/>
      case 'abc-details':
        return <AbcDetails user={this.props.user} business={this.props.business} permissions = {this.props.permissions} loadingMessage = {this.props.loadingMessage} pageLoaded={this.props.pageLoaded}/>
      case 'mariana-details':
        return <MarianaDetails user={this.props.user} business={this.props.business} permissions = {this.props.permissions} loadingMessage = {this.props.loadingMessage} pageLoaded={this.props.pageLoaded}/>
      case 'crunch-details':
        return <CrunchDetails user={this.props.user} business={this.props.business} permissions = {this.props.permissions} loadingMessage = {this.props.loadingMessage} pageLoaded={this.props.pageLoaded}/>
      case 'sub-policy':
        return <SubPolicy user={this.props.user} business={this.props.business} permissions = {this.props.permissions} loadingMessage = {this.props.loadingMessage} pageLoaded={this.props.pageLoaded}/>
      case 'fisikal-details':
        return <FisikalDetails user={this.props.user} business={this.props.business} permissions = {this.props.permissions} loadingMessage = {this.props.loadingMessage} pageLoaded={this.props.pageLoaded}/>
      case 'club-ready-details':
        return <ClubReadyDetails user={this.props.user} business={this.props.business} permissions = {this.props.permissions} loadingMessage = {this.props.loadingMessage} pageLoaded={this.props.pageLoaded}/>
    }
  }

  render() {
    const { user, business, permissions, loadingMessage, setCurrent, path} = this.props

    return (
      <div className='w-full min-h-screen bg-gray-50 pt-20 md:pt-8'>
        <div className='h-full'>
          <div className='max-w-8xl mx-auto px-6 lg:px-8 h-full'>
            <ConfigureNavbar user={user} business={business} permissions = {permissions} path={path}/>
            {this.renderSwitch()}
          </div>
        </div>
      </div>
    )
  }
}
