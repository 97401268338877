import React, { Fragment, useState, useEffect } from 'react';
import MrgEventsComponent from '../components/cardViewComponents/mrgEventsComponent'
import OpenEventsComponent from '../components/cardViewComponents/openEventsComponent'
import PendingEventsComponent from '../components/cardViewComponents/pendingEventsComponent';
import ApprovedEventsComponent from '../components/cardViewComponents/approvedEventsComponent'
import NoShowEventsComponent from '../components/cardViewComponents/noShowEventsComponent'; 
import CancelledEventsComponent from '../components/cardViewComponents/cancelledEventsComponent'
import { axiosGetRequest, updateEventsList, removeDeletedEvent } from './../../helpers/helpers'
import SentEventsComponent from './../components/cardViewComponents/sentEventsComponent'
import AvailableEventsComponent from './../components/cardViewComponents/availableEventsComponent'
import MyRequestsApprovedEventsComponent from './../components/cardViewComponents/myRequestsApprovedEventsComponent'
import AlertPopup from './../../../messenger/pages/chatComponents/assets/alertPopup'
import DisabledSubboardComponent from '../components/disabledSubboardComponent';

export default function CardView(props) {
  const { loadingMessage, business, activeTab, path, user } = props
  const [ loaded, setLoaded ] = useState(false)
  const currentDate = new Date();
  const [ startDate, setStartDate ] = useState((new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)).toLocaleDateString('en-GB'))
  const [ endDate, setEndDate ] = useState((new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)).toLocaleDateString('en-GB'))
  const [ approvedEvents, setApprovedEvents ] = useState([])
  const [ cancelledEvents, setCancelledEvents ] = useState([])
  const [ managerEvents, setManagerEvents ] = useState([])
  const [ noShowEvents, setNoShowEvents ] = useState([])
  const [ openEvents, setOpenEvents ] = useState([])
  const [ pendingEvents, setPendingEvents ] = useState([])
  const [ sentEvents, setSentEvents ] = useState([])
  const [ availableEvents, setAvailableEvents ] = useState([])
  const [ showAlert, setShowAlert ] = useState(false)
  const [ alertMessage, setAlertMessage ] = useState('')
  const [ alertType, setAlertType ] = useState('')
  const [ userHideDeclinedRequests, setUserHideDeclinedRequests ] = useState(user?.hide_declined_requests)
  const disabledSubBoardPage = (business?.banner_enabled)
  const [ availablePage, setAvailablePage ] = useState(1)
  const [ pageSize, setPageSize ] = useState(25)
  const [ sentEventsTotalCount, setSentEventsTotalCount ] = useState(0)
  const [ availableEventsTotalCount, setAvailableEventsTotalCount ] = useState(0)
  const [ approvedEventsTotalCount, setApprovedEventsTotalCount ] = useState(0)
  const [ paginationLoader, setPaginationLoader ] = useState(false)

  useEffect(() => {
    if(startDate !== '' && endDate !== ''){
      if(['all-requests'].includes(path)){
        processGetEventData()
      }else{
        setAvailablePage(1)
        processGetMyRequestsData(1)
      }
    }
  }, [startDate, endDate])

  const processGetEventData = () => {
    let url = `/api/v3/sub_overview/get_location_events?start_date=${startDate}&end_date=${endDate}&event_type=undefined&view_type=${activeTab}&initialize=false`
    axiosGetRequest(url).then((response) => {
      if(response.status == 200){
        setApprovedEvents(response?.results?.approved)
        setCancelledEvents(response?.results?.cancelled)
        setManagerEvents(response?.results?.manager)
        setNoShowEvents(response?.results?.no_show)
        setOpenEvents(response?.results?.open)
        setPendingEvents(response?.results?.pending)
        setLoaded(true)
      }else{
        setApprovedEvents([])
        setCancelledEvents([])
        setManagerEvents([])
        setNoShowEvents([])
        setOpenEvents([])
        setPendingEvents([])
        setLoaded(true)
      }
    })
  }

  const processGetMyRequestsData = (page) => {
    let url = `/api/v3/business_events?start_date=${startDate}&end_date=${endDate}&view_type=${activeTab}&page=${page}&page_size=${pageSize}`
    axiosGetRequest(url).then((result) => {
      if(result.status == 200){
        setSentEventsTotalCount(result?.sent_count)
        setAvailableEventsTotalCount(result?.available_count)
        setApprovedEventsTotalCount(result?.approved_count)
        if(page == 1){
          setApprovedEvents(result?.approved)
          setSentEvents(result?.sent)
          setAvailableEvents(result?.available)
          setUserHideDeclinedRequests(result?.user_hide_declined_requests)
        }
        else{
          setAvailableEvents(result?.available)
          setUserHideDeclinedRequests(result?.user_hide_declined_requests)
        }
        setLoaded(true)
        setPaginationLoader(false)
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 0);
      }else{
        setApprovedEvents([])
        setSentEvents([])
        setAvailableEvents([])
        setLoaded(true)
      }
    })
  }

  const handleChangePage = (page) => {
    setAvailablePage(page)
    setPaginationLoader(true)
    processGetMyRequestsData(page)
  }

  const handleAlert = (show, message, type) => {
    setShowAlert(show)
    setAlertMessage(message)
    setAlertType(type)
  }

  const handleGetEventData = () => {
    setLoaded(false)
    if(['all-requests'].includes(path)){
      processGetEventData()
    }else{
      processGetMyRequestsData(availablePage)
    }
  }

  const closeAlert = (data) => {
    setShowAlert(data)
    setAlertMessage('')
    setAlertType('')
  }

  const getEventDataWithoutLoad = () => {
    if(['all-requests'].includes(path)){
      processGetEventData()
    }else{
      setAvailablePage(1)
      processGetMyRequestsData(1)
    }
  }

  const handleUpdateEvents = (data) => {
    setApprovedEvents(updateEventsList(approvedEvents, data));
    if(['all-requests'].includes(path)){
      setCancelledEvents(updateEventsList(cancelledEvents, data));
      setManagerEvents(updateEventsList(managerEvents, data));
      setNoShowEvents(updateEventsList(noShowEvents, data));
      setOpenEvents(updateEventsList(openEvents, data));
      setPendingEvents(updateEventsList(pendingEvents, data));
    }else{
      setSentEvents(updateEventsList(sentEvents, data));
      setAvailableEvents(updateEventsList(availableEvents, data));
    }
  }

  const handleDeleteEvent = (data) => {
    setApprovedEvents(removeDeletedEvent(approvedEvents, data));
    if(['all-requests'].includes(path)){
      setCancelledEvents(removeDeletedEvent(cancelledEvents, data));
      setManagerEvents(removeDeletedEvent(managerEvents, data));
      setNoShowEvents(removeDeletedEvent(noShowEvents, data));
      setOpenEvents(removeDeletedEvent(openEvents, data));
      setPendingEvents(removeDeletedEvent(pendingEvents, data));
    }else{
      setSentEvents(removeDeletedEvent(sentEvents, data));
      setAvailableEvents(removeDeletedEvent(availableEvents, data));
    }
  }

  return(
    <Fragment>
      {showAlert &&
        <AlertPopup alert_type={alertType} alert_message={alertMessage} closeAlert={closeAlert} />
      }
      {!loaded &&
        <div className="msg-loading flex flex-col items-center w-full justify-center h-screen">
          <p className="mb-1 text-center px-5">{loadingMessage}</p>
          <img className = "w-9" src="/assets/business/loading.gif" />
        </div>
      }
      {loaded &&
        <Fragment>
          <div className={`mt-5 mx-auto flex-row lg:flex items-start gap-x-4 gap-y-8 lg:mx-0 lg:max-w-none overflow-x-auto ${(['all-requests'].includes(path)) ? 'card-view-width' : ''} px-8`}>
            {(['all-requests'].includes(path)) ?
              <Fragment>
                {business?.manager_request_gate &&
                  <MrgEventsComponent managerEvents={managerEvents} user={user} business={business} handleAlert={handleAlert} processGetEventData={handleGetEventData} path={path} getEventDataWithoutLoad={getEventDataWithoutLoad} handleUpdateEvents={handleUpdateEvents} handleDeleteEvent={handleDeleteEvent}/>
                }
                <OpenEventsComponent openEvents={openEvents} user={user} business={business} handleAlert={handleAlert} processGetEventData={handleGetEventData} path={path} getEventDataWithoutLoad={getEventDataWithoutLoad} handleUpdateEvents={handleUpdateEvents} handleDeleteEvent={handleDeleteEvent}/>
                <PendingEventsComponent pendingEvents={pendingEvents} user={user} business={business} handleAlert={handleAlert} processGetEventData={handleGetEventData} path={path} getEventDataWithoutLoad={getEventDataWithoutLoad} handleUpdateEvents={handleUpdateEvents} handleDeleteEvent={handleDeleteEvent}/>
                <ApprovedEventsComponent approvedEvents={approvedEvents} user={user} business={business} handleAlert={handleAlert} processGetEventData={handleGetEventData} path={path} getEventDataWithoutLoad={getEventDataWithoutLoad} handleUpdateEvents={handleUpdateEvents} handleDeleteEvent={handleDeleteEvent}/>
                <NoShowEventsComponent noShowEvents={noShowEvents} user={user} business={business} handleAlert={handleAlert} processGetEventData={handleGetEventData} path={path} getEventDataWithoutLoad={getEventDataWithoutLoad} handleUpdateEvents={handleUpdateEvents} handleDeleteEvent={handleDeleteEvent}/>
                <CancelledEventsComponent cancelledEvents={cancelledEvents} user={user} business={business} handleAlert={handleAlert} processGetEventData={handleGetEventData} path={path} getEventDataWithoutLoad={getEventDataWithoutLoad} handleUpdateEvents={handleUpdateEvents} handleDeleteEvent={handleDeleteEvent}/>
              </Fragment>
            :
              <Fragment>
                <SentEventsComponent sentEvents={sentEvents} user={user} business={business} handleAlert={handleAlert} processGetEventData={handleGetEventData} path={path} getEventDataWithoutLoad={getEventDataWithoutLoad} handleUpdateEvents={handleUpdateEvents} handleDeleteEvent={handleDeleteEvent} disabledSubBoardPage={disabledSubBoardPage} sentEventsTotalCount={sentEventsTotalCount}/>
                <AvailableEventsComponent availableEvents={availableEvents} user={user} business={business} handleAlert={handleAlert} processGetEventData={handleGetEventData} path={path} getEventDataWithoutLoad={getEventDataWithoutLoad} userHideDeclinedRequests={userHideDeclinedRequests} handleUpdateEvents={handleUpdateEvents} handleDeleteEvent={handleDeleteEvent} disabledSubBoardPage={disabledSubBoardPage} page={availablePage} pageSize={pageSize} availableEventsTotalCount={availableEventsTotalCount} handleChangePage={handleChangePage} loadingMessage={loadingMessage} paginationLoader={paginationLoader}/>
                <MyRequestsApprovedEventsComponent approvedEvents={approvedEvents} user={user} business={business} handleAlert={handleAlert} processGetEventData={handleGetEventData} path={path} getEventDataWithoutLoad={getEventDataWithoutLoad} handleUpdateEvents={handleUpdateEvents} handleDeleteEvent={handleDeleteEvent} disabledSubBoardPage={disabledSubBoardPage} approvedEventsTotalCount={approvedEventsTotalCount}/>
              </Fragment>
            }
          </div>
          {(['my-requests'].includes(path)) &&
            <div>
              <DisabledSubboardComponent business={business}/>
            </div>
          }
        </Fragment>
      }
    </Fragment>
  )
}