import React, { Component } from 'react'
import SettingsNavbar from './navbar'
import Users from '../users'
import Locations from '../locations'
import Skills from '../skills'
import Classes from '../classes'
import Regions from './../regions'
import Permissions from '../permissions'
import PermissionsForm from '../permissions/form'
import AccountSettings from '../account'
import { snakeCase } from '../../helpers'
import IntegratedUserPage from '../users/usersPageIntegrationComponents/integratedBusinessUserPage'

export default class SettingsLayout extends Component {

  componentDidMount(){
    if(Object.keys(this.props?.user).length !== 0){
      if (this.props?.user?.role_display === 'Fitness Instructor'){
        location.href = `/my-requests`
      }
    }
    this.props.setCurrent('Settings')
  }

  renderSwitch(){
    const { business } = this.props
    switch(this.props.path){
      case 'users':
        return (Object.keys(business)?.length > 0 && ((business?.clubready_integration || business?.fisikal_integration || (business?.mbo_integration && !business.mbo_staff_auto_sync_disabled && !location.href.includes('old_users_page')) || (business?.marianatek_integration && !business.marianatek_staff_auto_sync_disabled && !location.href.includes('old_users_page'))) ? <IntegratedUserPage user={this.props.user} business={this.props.business} permissions = {this.props.permissions} loadingMessage = {this.props.loadingMessage} pageLoaded={this.props.pageLoaded} setLoaded={this.props.setLoaded}/> : <Users user={this.props.user} business={this.props.business} permissions = {this.props.permissions} loadingMessage = {this.props.loadingMessage} pageLoaded={this.props.pageLoaded} setLoaded={this.props.setLoaded}/>))
      case 'locations':
        return <Locations user={this.props.user} business={this.props.business} permissions = {this.props.permissions} loadingMessage = {this.props.loadingMessage} pageLoaded={this.props.pageLoaded}/>
      case 'skills':
        return <Skills user={this.props.user} business={this.props.business} permissions = {this.props.permissions} loadingMessage = {this.props.loadingMessage} pageLoaded={this.props.pageLoaded}/>
      case 'classes':
        return <Classes user={this.props.user} business={this.props.business} permissions = {this.props.permissions} loadingMessage = {this.props.loadingMessage} pageLoaded={this.props.pageLoaded}/>
      case 'regions':
        return <Regions user={this.props.user} business={this.props.business} permissions = {this.props.permissions} loadingMessage = {this.props.loadingMessage} pageLoaded={this.props.pageLoaded}/>
      case 'permissions':
        return <Permissions user={this.props.user} business={this.props.business} permissions = {this.props.permissions} loadingMessage = {this.props.loadingMessage} pageLoaded={this.props.pageLoaded}/>
      case 'permissionsForm':
        return <PermissionsForm user={this.props.user} business={this.props.business} permissions = {this.props.permissions} loadingMessage = {this.props.loadingMessage} pageLoaded={this.props.pageLoaded}/>
      case 'account':
        return <AccountSettings user={this.props.user} business={this.props.business} permissions = {this.props.permissions} loadingMessage = {this.props.loadingMessage} pageLoaded={this.props.pageLoaded}/>
    }
  }

  render() {
    const { user, business, permissions, loadingMessage, setCurrent, path} = this.props
    return (
      <div className='flex-1 flex flex-col overflow-hidden min-h-screen bg-gray-50 pt-20 md:pt-8'>
        <div className='h-full'>
          <div className='mx-auto px-4 lg:px-8 h-full'>
            <SettingsNavbar user={user} business={business} permissions = {permissions} path={path}/>
            {this.renderSwitch()}
          </div>
        </div>
      </div>
    )
  }
}
