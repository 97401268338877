import React, { useEffect, useState } from "react";
import { useChatContext, useChannelStateContext, ChannelHeaderProps } from "stream-chat-react";
import Avatar from './avatar/avatar'
import ChannelMoreActionComponent from './channelMoreActionComponent';

export default function CustomChannelHeader (props) {
	const { title, handleSetShowInfo, loadingInProgress, channelDataLoaded, showEditButton, showDeleteButton, setShowInfoComponent, setShowNotificationList, handleShowDeleteComfirmation, processHideChannel, notification, notificationDuration } = props;
  const { channel } = useChannelStateContext();
  const { client } = useChatContext();

  const handlefetchChannelData = () => {
    if(loadingInProgress || channelDataLoaded) return
    handleSetShowInfo()
  }

  const renderDmName = (members, channel) => {
    const defaultName = client.user.name;

    if (!members.length || members.length === 1) {
      return(<span>{members[0]?.user.name || members[0]?.user.id || defaultName}</span>)
    }

    let names = members.map((usr) => {return usr.user.name})
    let len = 2
    let shortName = []
    for(let i = 0; i < names?.length; i++){
      if(i < len){
        shortName.push(names[i])
      }else{
        // shortName.push(`${names?.length - len}${names?.length > 99 ? '+' : ''} ${(names?.length - len) > 1 ?'others' : 'other'}`)
        shortName.push(`${channel.data?.member_count - 3} ${(channel.data?.member_count - 3) > 1 ?'others' : 'other'}`)
        break
      }
    }
    let groupName = shortName.join(', ') 
    
    return(<span>{groupName}</span>)
  }

  const DmHeaderPreview = () => {
    const members = Object.values(channel.state.members).filter(
      ({ user }) => user.id !== client.userID
    );
    members.sort((a, b) => a.user.name.localeCompare(b.user.name, 'es', {sensitivity: 'base'}))
    const defaultName = "Johnny Blaze";

    return (
      <React.Fragment>
        {/* {checkDmInfoPageVisible() === true ?
            <span style={{ display: 'flex' }} className={`cursor-pointer ${loadingInProgress ? 'relative cursor-not-allowed' : ''}`} onClick={() => setShowInfoComponent(true)}>
              <DmImageAvtar />
              {loadingInProgress &&
                <div className="flex items-center justify-center top-0 bottom-0 left-3 absolute">
                  <svg className="animate-spin h-3.5 w-3.5 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </div>
              }
            </span>
          :
          <Avatar
            image={members[0]?.user.image || undefined}
            name={members[0]?.user.name || members[0]?.user.id}
            size={40}
            // shape="rounded"
          />
        } */}
        <div className="str-chat__header-livestream-left str-chat__channel-header-end">
          <p className="str-chat__header-livestream-left--title str-chat__channel-header-title">
            {renderDmName(members, channel)}
          </p>
          <p className='str-chat__header-livestream-left--members str-chat__channel-header-info'>
            {channel.data?.member_count > 0 && `${channel.data?.member_count} members, ${channel.state?.watcher_count} online`} 
          </p>
        </div>
      </React.Fragment> 
    )
  }

  const ChannelHeaderPreview = () =>{
    const members = Object.values(channel.state.members).filter(
      ({ user }) => user.id !== client.userID
    );
    members.sort((a, b) => a.user.name.localeCompare(b.user.name, 'es', {sensitivity: 'base'}))
    const defaultName = "Johnny Blaze";

    return(
      <React.Fragment>
        {/* {checkDmInfoPageVisible() === true ?
          <span style={{ display: 'flex' }} className={`cursor-pointer ${loadingInProgress ? 'relative cursor-not-allowed' : ''}`} onClick={() => setShowInfoComponent(true)}>
            <Avatar
              image={channel?.data.image || undefined}
              name={channel?.data.name || channel?.data.id}
              size={40}
              // shape="rounded"
            />
            {loadingInProgress &&
              <div className="flex items-center justify-center top-0 bottom-0 left-3 absolute">
                <svg className="animate-spin h-3.5 w-3.5 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </div>
            }
          </span>
          :
          <Avatar
            image={channel?.data.image || undefined}
            name={channel?.data.name || channel?.data.id}
            size={40}
            // shape="rounded"
          />
        } */}
        <div className="str-chat__header-livestream-left str-chat__channel-header-end">
          <p className="str-chat__header-livestream-left--title str-chat__channel-header-title text-base">
            {channel.data?.name || 'Channel'}
          </p>
          <p className='str-chat__header-livestream-left--members str-chat__channel-header-info'>
            {channel.data?.member_count > 0 && `${channel.data?.member_count} members, ${channel.state?.watcher_count} online`} 
          </p>
        </div>
      </React.Fragment>
    )
  }

  const checkDmInfoPageVisible = () => {
    if(channel?.type === 'team'){
      return true
    }else{
      const members = Object.values(channel.state.members).filter(
        ({ user }) => user.id !== client.userID
      );
      return (members && members?.length > 0)
    }
  }

  const DmImageAvtar = () => {
    const members = Object.values(channel.state.members).filter(
      ({ user }) => user.id !== client.userID
    );
    members.sort((a, b) => a.user.name.localeCompare(b.user.name, 'es', {sensitivity: 'base'}))
    const defaultName = "Johnny Blaze";

    if (!members.length || members.length === 1) {
      return(
        <React.Fragment>
          <Avatar
            image={members[0]?.user.image || undefined}
            name={members[0]?.user.name || members[0]?.user.id}
            size={40}
            // shape="rounded"
          />
        </React.Fragment>
      ) 
    }
    
    return(
      <React.Fragment>
        {channel?.data.image && channel?.data.image !== '' ? 
          <Avatar
            image={channel?.data.image || members[0]?.user.image || undefined}
            name={members[0]?.user.name || members[0]?.user.id}
            size={40}
            // shape="rounded"
          />
        :
          <Avatar
            image={members[0]?.user.image || undefined}
            name={members[0]?.user.name || members[0]?.user.id}
            size={40}
            // shape="rounded"
          />
        }
      </React.Fragment>
    ) 
  }

	return (
		<div className="str-chat__header-livestream str-chat__channel-header border-b p-5">
      {channel?.type === 'team' ? <ChannelHeaderPreview /> : <DmHeaderPreview />}
      <div className="str-chat__header-livestream-right str-chat__channel-header-end items-center">
        {/* {checkDmInfoPageVisible() === true &&
          <span style={{ display: 'flex' }} className="">
            {channel?.type === 'team' ?
              <Avatar
                image={channel?.data.image || undefined}
                name={channel?.data.name || channel?.data.id}
                size={40}
                // shape="rounded"
              />
              :
              <DmImageAvtar />
            }
          </span>
        } */}
        <ChannelMoreActionComponent
          channel={channel}
          loadingInProgress={loadingInProgress}
          setShowInfoComponent={setShowInfoComponent}
          handlefetchChannelData={handlefetchChannelData}
          showEditButton={showEditButton}
          showDeleteButton={showDeleteButton}
          setShowNotificationList={setShowNotificationList}
          handleShowDeleteComfirmation={handleShowDeleteComfirmation}
          processHideChannel={processHideChannel}
          notification={notification}
          notificationDuration={notificationDuration}
        />
      </div>
		</div>
	)
}