function getHeaders(token, subdomain){  
  if (token !== undefined) {
    let obj = {
      'Accept': 'application/vnd.netgym.v3',
      'Content-Type': 'application/json',
      'Authorization': eval(`${token}`),
      'subdomain': localStorage.getItem("businessID")
    }
    return obj
  }  
}

export const updateBusiness = async (user_token, subdomain, business) => {  
  return await fetch(`/api/v3/update_business`,{
    method: "POST",
    headers: getHeaders(user_token, subdomain),
    body: JSON.stringify({ 
      name: business.name,
      coordinator_email: business.coordinator_email,
      urgent_request_email: business.urgent_request_email,
      request_reason_optional: business.request_reason_optional,
      employee_id_optional: business.employee_id_optional,
      admin_monthly_summary: business.admin_monthly_summary,
      regional_monthly_summary: business.regional_monthly_summary,
      manager_monthly_summary: business.manager_monthly_summary,
      business_hours: business?.business_hours,
      class_syncing_errors_email: business?.class_syncing_errors_email
    })
  });
}

export const uploadLogo = async (user_token, subdomain, file) => {
  const formData = new FormData();
  formData.append(
    "file",
    file,
    file.name
  );
    
  return await fetch(`/api/v3/update_business`,{
    method: "POST",    
    body: formData,
    headers: {
      'Accept': 'application/vnd.netgym.v3',
      'Authorization': eval(`${user_token}`),
      'subdomain': localStorage.getItem("businessID")
    }
  });
}

export const uploadDefaultThumbnail = async (user_token, subdomain, formData) => {
  return await fetch(`/api/v3/set_thumbnail`,{
    method: "POST",    
    body: formData,
    headers: {
      'Accept': 'application/vnd.netgym.v3',
      'Authorization': eval(`${user_token}`),
      'subdomain': localStorage.getItem("businessID")
    }
  });
}

export const deleteDefaultThumbnail = async (user_token, subdomain, resource_id, type) => {  
  return await fetch(`/api/v3/set_thumbnail?id=${resource_id}&type=${type}`,{
    method: "DELETE",
    headers: getHeaders(user_token, subdomain)
  });
}  


