import React, { Fragment } from "react";
import { Link } from "react-router-dom";

export default function HeaderComponent(props) {
  const { path, subdomainBusinessImage } = props;
  const getPageHost = () => {
    let pagehost = location.host
    let splitedHost = pagehost.split('.')
    let protocol = location.protocol
    return splitedHost.length >=2 ? `${protocol}//${splitedHost[splitedHost.length - 2]}.${splitedHost[splitedHost.length - 1]}` : ''
  }
  return (
    <Fragment>
      <div className="fixed w-full z-40 flex-shrink-0 top-0 flex h-15 bg-black shadow">
        <div className="flex justify-center items-center px-4 h-full w-full">
          <div className="flex items-center container justify-between px-8 lg:px-40">
            {path == 'business_select' ? 
              <Link to={"/signin"}>
                <img src="/assets/business/logo.png" className="h-5" />
              </Link>
              :
              path !== 'signin' ?
              <Link to={"/signin"}>
                <img src={subdomainBusinessImage} className="business-image-login-logo" />
              </Link>
              :
              <div></div>
            }
            {path == 'signin' ? 
              <a href="/" className="text-sm text-white">Home</a>
            :
            path == 'business_select' ? 
              <a href="/" className="text-sm text-white">Login</a>
            :
            ['forget-password', 'reset-password'].includes(path) ?
              <Link to={"/signin"} className="text-sm text-white">Login</Link>
            :
              <a href={getPageHost()+"/signin"} className="text-sm text-white">Login</a> 
            }
          </div>
        </div>
      </div>
    </Fragment>
  );
} 