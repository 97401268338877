import React, { Component, Fragment } from 'react'
import { updateBusiness, uploadLogo, uploadDefaultThumbnail, deleteDefaultThumbnail } from './helpers'
import { getSudomain, setTitle, timeZones, checkSettingsPermissios } from './../../helpers'
import { ResourceThumbnailMinHeight, ResourceThumbnailMinWidth, ResourceThumbnailMaxHeight, ResourceThumbnailMaxWidth } from "../../resource_hub/helpers"
import Cookies from 'js-cookie';
import { ExclamationIcon, TrashIcon } from '@heroicons/react/outline'
import { Dialog, Transition } from '@headlessui/react'
import { Switch } from '@headlessui/react'
import DatePicker from "react-datepicker";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default class AccountSettings extends Component {
  constructor(props){
    super(props);
    this.state = {
      business: props.business,
      show: false,
      alert_type: '',
      alert_message: '',
      thumbnailUploading: false,
      deleteThumbnailPopupOpen: false,
      defaultPreviewImg: null,      
			thumbnailMetadata: {},
			attachment: null,
      businessLogoFileName: '',
      businessLogoFileShortName: ''
    }
    this.thumbnailRef = React.createRef();
  }
  
  componentDidMount = () => {
    setTitle('account_info')
    if(Object.keys(this.props.user).length !== 0){
      checkSettingsPermissios('account_info', this.props)
    }
  }

  componentWillUnmount() {
    this.setState = (state,callback)=>{
      return;
    };
  }

  handleChange = (e) => {
    const { name } = e.target
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
    this.setState({
      business: {
        ...this.state.business,
        [name]: value
      }
    })
  }

  setGender1(e) {
    this.setState({
      business: {
        ...this.state.business,
        request_reason_optional: !this.state.business?.request_reason_optional
      }
    })
  }

  setGender2(e) {
    this.setState({
      business: {
        ...this.state.business,
        employee_id_optional: !this.state.business?.employee_id_optional
      }
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { name } = this.state.business;
    this.setState({
      submitted: true
    });
    if (name !== "") {
      this.setState({thumbnailUploading: true, show: false}, () => {
        this.saveAll()
      })
    } else {
      console.log("Error creating location");
    }
  }

  saveAll = async () => {
    const response = await this.submitForm();
    let message = "Business details Updated";
    if(response.status === 200) { 
      if(Boolean(this.state.attachment)){
        const fileUploadResponse = await this.uploadAttachmentAsync();
        if(fileUploadResponse.status === 200){
          message = "Business details Updated and Thumbnail uploaded Successfully"
          this.setState({
            alert_message: message,
            alert_type: 'success', 
            show: true,           
            loaded: true,
            thumbnailUploading: false,
            showNotifyAudiencePopup: false
          })			
          this.setState({show:true},()=>{
            window.setTimeout(()=>{
              this.setState({show:false, uploaded: true})
              window.location.reload(true);
            },3000)
          });	
        }else {
          this.setState({
            alert_message: "Uploading thumbnail failed.Try again",
            alert_type: 'danger', 
            show: true,           
            loaded: true,
            thumbnailUploading: false,
            showNotifyAudiencePopup: false
          })			
          this.setState({show:true},()=>{
            window.setTimeout(()=>{
              this.setState({show:false})
            },3000)
          });					
        }
      }else {
        this.setState({
          alert_message: message,
          alert_type: 'success', 
          show: true,           
          loaded: true,
          thumbnailUploading: false,
          showNotifyAudiencePopup: false
        })			
        this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false, uploaded: true})
            window.location.reload(true);
          },3000)
        });	        
      }  
    }else {
        this.setState({
          alert_message: response.error,
          alert_type: 'danger',
          show: true,
          loaded: true
        })
    }

  }

  submitForm = (e) => {    
    return updateBusiness(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), this.state.business).then(      
      response => response.json()
    ).then(result => {
			return result;
		}).catch (error => {
			return error;
		})    
  }

  upload = (e) => {
    e.preventDefault()
    let fullName = e.target.files[0].name
    let shortName = ''
    if(fullName.length > 22){
      let startText = fullName.slice(0, 11);
      let endText = fullName.slice(-11);
      shortName = `${startText}...${endText}`
    }else{
      shortName = fullName
    }
    this.setState({
      businessLogoFileName: fullName,
      businessLogoFileShortName: shortName
    })
    
    uploadLogo(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), e.target.files[0]).then(      
      response => response.json()
      .then(result => { 
        if(result.status === 200){
          this.setState({
            show: true,
            alert_message: 'Logo Updated Successfully',
            alert_type: 'success',            
            loaded: true
          })
          setTimeout(function(){ window.location.reload() }, 1500);
        }else{
          this.setState({
            alert_message: result.error,
            alert_type: 'danger',            
            loaded: true
          })
        }        
      })
    )
  }

  getDimensions = async (f) => {
		let meta = {};
		const fileCallbackToPromise = (fileObj) => {
			return Promise.race([
				new Promise((resolve) => {
					if (fileObj instanceof HTMLImageElement) fileObj.onload = resolve;
					else fileObj.onloadedmetadata = resolve;
				}),
				new Promise((_, reject) => {
					setTimeout(reject, 2000);
				})
			]);
		};

		const objectUrl = URL.createObjectURL(f);
		const img = document.createElement('img');
		img.src = objectUrl;
		try {
			await fileCallbackToPromise(img);
			this.setState({ isMetadataPresent: true });
		} catch (error) {
			this.setState({ isMetadataPresent: false });
		}
		return {
			width: img.width,
			height: img.height,
		};
	};


  setThumbnailData = async (e) => {
		this.setState({ ...this.state, [e.target.name]: e.target.files[0] });
		//reset if there is alert
		this.setState({
			show: false,
			alert_message: "",
		})	    
		
    e.preventDefault()
    const file = e.target.files[0];
		const {files} = e.target;
    const meta = await this.getDimensions(file);
    if(meta.width < ResourceThumbnailMinWidth || meta.height < ResourceThumbnailMinHeight) {
        this.setState({
          show: true,
          // alert_message: `Upload Failed: Your image needs to be between ${ResourceThumbnailMinWidth}x${ResourceThumbnailMinHeight}px and ${ResourceThumbnailMaxWidth}x${ResourceThumbnailMaxHeight}px - please try again.`,
          alert_message: 'Thumbnail must have a minimum width/height '+ResourceThumbnailMinWidth+"x"+ResourceThumbnailMinHeight+"px",
          alert_type: 'danger',            
          loaded: true,
          attachment: null,
          defaultPreviewImg: null
        }, ()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },5000)
        })  
        this.thumbnailRef.current.value = null;
        return true;    
    }
    // if(meta.width > ResourceThumbnailMaxWidth || meta.height > ResourceThumbnailMaxHeight) {
    //   this.setState({
    //     show: true,
    //     alert_message: `Upload Failed: Your image needs to be between ${ResourceThumbnailMinWidth}x${ResourceThumbnailMinHeight}px and ${ResourceThumbnailMaxWidth}x${ResourceThumbnailMaxHeight}px - please try again.`,
    //     // alert_message: 'Width/Height of Thumbnail must not exceed '+ResourceThumbnailMaxWidth+"x"+ResourceThumbnailMaxHeight+"px",
    //     alert_type: 'danger',            
    //     loaded: true,
    //     attachment: null
    //   }, ()=>{
		// 		window.setTimeout(()=>{
		// 			this.setState({show:false})
		// 		},5000)
		// 	})   
    //   return true;   
    // }
    this.setPreviewHandler(file);
    const details = {
      name: file.name,
      size: file.size,
      width: meta.width,
      height: meta.height,
    }    
		this.setState({thumbnailMetadata: details})
	}  


	uploadAttachmentAsync = () => {
		const {thumbnailMetadata, attachment} = this.state;
		if(Boolean(this.state.attachment)){
			const formData = new FormData();
			formData.append( "thumbnail", attachment)
			formData.append( "content_type", attachment.type)
			formData.append( "name", thumbnailMetadata.name)
			formData.append( "size", thumbnailMetadata.size)
			formData.append( "width", thumbnailMetadata.width)
			formData.append( "height", thumbnailMetadata.height)
			//important
      formData.append( "type", "Business")
      formData.append( "id", this.state.business.id)	    
    
			return uploadDefaultThumbnail(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), formData )
			.then(result => {
				return result;
			}).catch (error => {
				return error;
			})
		}
  }    

	deleteThumbnail = (business_id) => {
		if(business_id){
			this.setState({deleteThumbnailPopupOpen: false}, () => {
				deleteDefaultThumbnail(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), business_id, "Business").then(      
					response => response.json()
				)
				.then(result => {
					if(result.status === 200){
						this.setState({
							alert_message: result.message,
							alert_type: 'success', 
							show: true,
						})
						this.setState({show:true},()=>{
							window.setTimeout(()=>{
								this.setState({show:false})
								window.location.reload(true)
							},3000)
						});
					}else{
						this.setState({
							alert_message: result.error,
							alert_type: 'danger', 
							show: true,     
						})
						this.setState({show:true},()=>{
							window.setTimeout(()=>{
								this.setState({show:false})
								window.location.reload(true)
							},3000)
						});
					}
				})	
			})			
		}
	} 

	deleteThumbnailHandler = () => {
		const { deleteThumbnailPopupOpen } = this.state
		this.setState({deleteThumbnailPopupOpen: !deleteThumbnailPopupOpen})
	}

	setPreviewHandler = (file) => {
		this.setState({defaultPreviewImg: URL.createObjectURL(file)})
	}

	removeAttachmentHandler = () => {
		this.setState({defaultPreviewImg: null, attachment: null})
	}	
  
  render() {
    const { business, show, alert_type, alert_message, thumbnailUploading, deleteThumbnailPopupOpen, defaultPreviewImg, businessLogoFileName, businessLogoFileShortName } = this.state

    return (
      <React.Fragment>
        {show &&
          <React.Fragment>
            {alert_type === 'success' &&
              <div className="w-3/5 flex items-center bg-green-50 rounded-md px-4 py-3.5 mb-1 fixed top-1 z-50 left-0 right-0 mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-400 mr-3.5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
                <div className="text-sm text-green-800 font-medium">
                  {alert_message}
                </div>
                <a className="ml-auto" onClick={(e) => this.setState({show: false})}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </a>
              </div>
            }
            {alert_type === 'danger' &&
              <div className="w-3/5 flex items-center bg-red-100 rounded-md px-4 py-3.5 mb-1 fixed top-1 z-50 left-0 right-0 mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-red-300 mr-3.5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                </svg>
                <div className="text-sm text-red-700 font-medium">
                  {alert_message}
                </div>
                <a className="ml-auto" onClick={(e) => this.setState({show: false})}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </a>
              </div>
            }  
          </React.Fragment>
        }
        <div className='bg-white rounded-md shadow px-6 mb-10'>
          <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start py-5'>
            <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Company Name</label>
            <div className='mt-1 sm:mt-0 sm:col-span-2'>
              <input type="text" className="lg:w-80 border text-sm h-10 rounded-md px-2 py-1.5 w-full shadow-sm" name="name" defaultValue={business?.name} onChange={(e) => this.handleChange(e)}/>
            </div>
          </div>
          <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
            <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Start Date</label>
            <div className='mt-1 sm:mt-0 sm:col-span-2'>
              <DatePicker
                selected={Object.keys(business).length === 0  ? new Date() : business?.start_date === '' ? new Date() : new Date(Date.parse(business?.start_date))}
                name="hire_date"
                className="lg:w-80 border text-sm h-10 rounded-md px-2 py-1.5 w-full shadow-sm cursor-not-allowed"
                dateFormat={business?.date_format == "mm/dd/yy" ? "M/d/yyyy" : "d/M/yyyy"}
                disabled={true}
              />
            </div>
          </div>
          <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
            <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Company Logo</label>
            <div className='mt-1 sm:mt-0 sm:col-span-2'>
              <div>
                {business?.custom_logo &&
                  <div className='bg-gray-200 mb-2 py-1 px-2 max-w-28 rounded-sm'>
                    <img className="w-28" src={business?.profile_url} alt="logo" />
                  </div>
                }
                {!business?.custom_logo &&
                  <img src={window.location.origin + '/assets/logo-black.png'} className="w-28 mb-2" alt="logo" />
                }
              </div>
              <div>
                {business?.custom_logo &&
                  <div className="items-center flex gap-1">
                    <div className={`shadow-sm text-black relative p-1 py-1.5 leading-4 items-center justify-center flex gap-1 wc-102 choose-file-button-class choose-file-button`}>
                    {`Choose file`}
                      <input type="file" className={`absolute w-full top-0 left-0 bottom-0 opacity-0`} accept="image/*" onChange={(e) => this.upload(e)}></input>
                    </div>
                    {businessLogoFileName !== '' &&
                      <div className='choose-file-button-div relative'>
                        <div className="">
                          {businessLogoFileShortName}
                        </div>
                        <div className="title shadow-md border border-black absolute top-7 text-sm px-1 bg-white whitespace-nowrap">
                          {businessLogoFileName}
                        </div>
                      </div>
                    }
                  </div>
                }
                {!business?.custom_logo &&
                  <input type="file" onChange={(e) => this.upload(e)} accept="image/*"/>
                }
              </div>
            </div>
          </div>
          <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
            <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Resources Automated Thumbnail</label>
            <div className='mt-1 sm:mt-0 sm:col-span-2'>
              <div className='relative'>
                {(() => {
                  if (business?.resource_thumbnail && (!Boolean(defaultPreviewImg))) {
                    return (
                      <img className="w-28 mb-2" src={business?.resource_thumbnail} alt="thumbnail" />
                    )
                  } else if (Boolean(defaultPreviewImg)) {
                    return (
                      <div className='border border-2 border-gray-400 shadow w-48 pl-3 rounded relative'>
                        <p className='mb-2 text-gray-400'>Preview</p>
                        <img className='mb-2 w-32' src={defaultPreviewImg} />
                        {!thumbnailUploading && (
                          <div className='absolute -top-2 right-0 p-2 text-gray-400 cursor-pointer' title="Remove attachment" 
                            onClick={(e) => this.removeAttachmentHandler()}
                          >x</div>
                        )}
                      </div>
                    )
                  } else {
                    return (
                      <></>
                    )
                  }	
                })()}
                {thumbnailUploading && (
                  <div className="inline-flex items-center px-4 pl-0 py-2 font-semibold leading-6 text-sm text-white bg-white" disabled="">
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-800" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    <span className='text-gray-800'>
                      uploading...
                    </span>
                  </div>
                )}
                {business?.resource_thumbnail && !Boolean(defaultPreviewImg) && (
                  <div className='absolute right-0 cursor-pointer' title="Delete Thumbnail">
                    <TrashIcon className="h-6 w-6 text-red-600" aria-hidden="true" onClick={(e) => this.deleteThumbnailHandler()} />
                  </div>
                )}		                
              </div>
              <div>
                <input ref={this.thumbnailRef} type="file" onChange={(e) => this.setThumbnailData(e)} name="attachment" accept="image/*"/>
              </div>
            </div>
          </div>            
          <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
            <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Schedule Coordinator
              <span className='block text-xs'>*Schedule Coordinator will be CC'd on all Approved Sub Requests receipts</span>
            </label>
            <div className='mt-1 sm:mt-0 sm:col-span-2'>
              <input type="text" className="lg:w-80 border rounded-md px-2 py-1.5 w-full text-sm h-10 shadow-sm" name="coordinator_email" defaultValue={business.coordinator_email} placeholder="Enter Schedule Coordinator Email" onChange={(e) => this.handleChange(e)}/>
            </div>
          </div>
          <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
            <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Urgent Sub Requests - CC
              <span className='block text-xs'>*This person will be CC'd on all urgent sub requests.</span>
            </label>
            <div className='mt-1 sm:mt-0 sm:col-span-2'>
              <input type="text" className="lg:w-80 text-sm h-10 border rounded-md px-2 py-1.5 w-full shadow-sm" name="urgent_request_email" defaultValue={business.urgent_request_email} placeholder="Add Email Address" onChange={(e) => this.handleChange(e)}/>
            </div>
          </div>
          <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
            <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Class Syncing Errors
              <span className='block text-xs'>*NetGym will notify these folks when there's an issue with syncing a class.</span>
            </label>
            <div className='mt-1 sm:mt-0 sm:col-span-2'>
              <input type="text" className="lg:w-80 text-sm h-10 border rounded-md px-2 py-1.5 w-full shadow-sm" name="class_syncing_errors_email" defaultValue={business.class_syncing_errors_email} placeholder="Add Email Address" onChange={(e) => this.handleChange(e)}/>
            </div>
          </div>
          <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
            <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Make “Reason for Request” required?
            </label>
            <div className='mt-1 sm:mt-0 sm:col-span-2 flex gap-2 items-center'>
              <Switch
                id={`request_reason_optional-1`}
                checked={!business?.request_reason_optional}
                name="request_reason_optional"
                onChange={(e) => this.setGender1(e)}
                className={classNames(
                  !business?.request_reason_optional ? 'bg-gray-900' : 'bg-gray-200',
                  'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
                )}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    !business?.request_reason_optional ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                  )}
                />
              </Switch>
              <label className='ml-2 text-sm' htmlFor="request_reason_optional-1">
                {!business?.request_reason_optional ? 'Yes' : 'No'}
              </label>
            </div>
          </div>
          <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
            <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Make “Employee ID” required?
            </label>
            <div className='mt-1 sm:mt-0 sm:col-span-2 flex gap-2 items-center'>
              <Switch
                id={`employee_id_optional-1`}
                checked={!business?.employee_id_optional}
                name="employee_id_optional"
                onChange={(e) => this.setGender2(e)}
                className={classNames(
                  !business?.employee_id_optional ? 'bg-gray-900' : 'bg-gray-200',
                  'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
                )}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    !business?.employee_id_optional ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                  )}
                />
              </Switch>
              <label className='ml-2 text-sm' htmlFor="employee_id_optional-1">
                {!business?.employee_id_optional ? 'Yes' : 'No'}
              </label>
            </div>
          </div>
          <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
            <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Send Monthly Summary
            </label>
            <div className='mt-1 sm:mt-0 sm:col-span-2'>
              <div>
                <div className='relative flex flex-wrap items-start gap-3'>
                  <div className='flex items-center'>
                    <div className='flex items-center h-5'>
                      <input id="admin_monthly_summary" aria-describedby="comments-description" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" name="admin_monthly_summary" checked={business?.admin_monthly_summary} onChange={(e) => this.handleChange(e)}/>
                    </div>
                    <div className='ml-1.5 text-sm'>
                      <label htmlFor="admin_monthly_summary" className="font-medium text-gray-700">Business Admin</label>
                    </div>
                  </div>
                  <div className='flex items-center'>
                    <div className='flex items-center h-5'>
                      <input id="regional_monthly_summary" aria-describedby="comments-description" name="regional_monthly_summary" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" checked={business?.regional_monthly_summary} onChange={(e) => this.handleChange(e)}/>
                    </div>
                    <div className='ml-1.5 text-sm'>
                      <label htmlFor="regional_monthly_summary" className="font-medium text-gray-700">Regional Admin</label>
                    </div>
                  </div>
                  <div className='flex items-center'>
                    <div className='flex items-center h-5'>
                      <input id="manager_monthly_summary" aria-describedby="comments-description" name="manager_monthly_summary" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" checked={business?.manager_monthly_summary} onChange={(e) => this.handleChange(e)}/>
                    </div>
                    <div className='ml-1.5 text-sm'>
                      <label htmlFor="manager_monthly_summary" className="font-medium text-gray-700">Fitness Manager</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
            <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2"></label>
            <div className='mt-1 sm:mt-0'>
              <div>
                <a className={`flex bg-dark-blue hover:bg-gray-800 text-white inline-block px-4 py-2 border shadow rounded-md cursor-pointer ${thumbnailUploading ? 'w-52' : 'w-44'}`} onClick={(e) => this.handleSubmit(e)}>
                  {(() => {
                    if (thumbnailUploading) { 
                      return (<span className='ml-1'><img className = "w-6 h-6" src="/assets/business/white-loader.gif" /></span>)
                    }
                  })()}		
                  Update Company
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* confirm pop to delete thumbnail */}
        <Transition.Root show={deleteThumbnailPopupOpen} as={Fragment}>
					<Dialog as="div" className="relative z-10" onClose={() => this.deleteThumbnailHandler()}>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</Transition.Child>

						<div className="fixed inset-0 z-10 overflow-y-auto">
							<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
								<Transition.Child
									as={Fragment}
									enter="ease-out duration-300"
									enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
									enterTo="opacity-100 translate-y-0 sm:scale-100"
									leave="ease-in duration-200"
									leaveFrom="opacity-100 translate-y-0 sm:scale-100"
									leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								>
									<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
										<div className="sm:flex sm:items-start">
											<div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
												<ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
											</div>
											<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
												<Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
													Delete Thumbnail?
												</Dialog.Title>
											</div>
										</div>
										<div className="mt-5 sm:mt-4 sm:ml-10 sm:flex sm:pl-4">
											<button
												type="button"
												className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto sm:text-sm"
												onClick={() => this.deleteThumbnail(business.id)}
											>
												Delete
											</button>
											<button
												type="button"
												className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
												onClick={() => this.deleteThumbnailHandler()}
											>
												Cancel
											</button>
										</div>
									</Dialog.Panel>
								</Transition.Child>
							</div>
						</div>
					</Dialog>
    		</Transition.Root>        
      </React.Fragment>
    )
  }
}
