import React, { Fragment } from "react";
import FilterIcon from "./../../../../assets/filterIcon";
import EditIcon from "./../../../../assets/editIcon";
import CardComponent from './../cardComponent';
import EmptyCardsMessageComponent from '../emptyCardsMessageComponent';

export default function SentEventsComponent(props) {
  const { sentEvents, user, business, handleAlert, processGetEventData, path, getEventDataWithoutLoad, handleUpdateEvents, handleDeleteEvent, disabledSubBoardPage, sentEventsTotalCount } = props;
  return (
    <Fragment>
      <div className="w-full mb-7 lg:mb-0">
        <div className="bg-white shadow-md flex items-center justify-between rounded-lg px-3">
          <div className="flex text-black text-sm font-medium py-4">
            Sent {!disabledSubBoardPage && sentEvents?.length > 0 ? `(${sentEventsTotalCount})` : ""}
          </div>
          <div className="flex gap-1 hidden">
            <div className="w-8 h-8 rounded-full flex items-center justify-center bg-gray-100">
              <EditIcon classNames={"w-4 h-4 text-gray-500"} />
            </div>
            <div className="w-8 h-8 rounded-full flex items-center justify-center bg-gray-100">
              <FilterIcon classNames={"w-5 h-5 text-gray-500"} />
            </div>
          </div>
        </div>
        <div className="mt-3">
          {!disabledSubBoardPage &&
            <Fragment>
              {sentEvents?.length > 0 ?
                <Fragment>
                  {sentEvents?.map((event, i) => (
                    <div key={i}>
                      <CardComponent event={event} listType={"Sent"} user={user} business={business} handleAlert={handleAlert} processGetEventData={getEventDataWithoutLoad} path={path} handleUpdateEvents={handleUpdateEvents} handleDeleteEvent={handleDeleteEvent}/>
                    </div>
                  ))}
                </Fragment>
              :
                <EmptyCardsMessageComponent listType={"Sent"}/>
              }
            </Fragment>
          }
        </div>
      </div>
    </Fragment>
  );
}