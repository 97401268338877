import React, { Fragment, useState, useEffect } from 'react';
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import { hideItems, chekLocationNameCount, seprateOutLocations, handleSaveFilters } from './../../../helpers/helpers'
import SearchIcon from './../../../assets/searchIcon'
import { debounce } from "lodash";

export default function LocationFilterComponent(props) {
  const { regionLocationsList, selectedFilterLocations, hanldleSelectFilterLocations, selectedFilterRegions, showRegions, user } = props
  const [ selectedLocations, setSelectedLocations ] = useState(selectedFilterLocations)
  const [ selectedRegions, setSelectedRegions ] = useState(selectedFilterRegions)
  const locationList = seprateOutLocations(regionLocationsList) || []
  const [ regionsList, setRegionsList ] = useState(regionLocationsList || [])
  const [ searchValue, setSearchValue ] = useState('')
  const [ searchedLocationsList, setSearchedLocationsList ] = useState(locationList || [])

  useEffect(() => {
    setSelectedLocations(selectedFilterLocations)
    setSelectedRegions(selectedFilterRegions)
    seprateOutLocations(regionLocationsList)
    setRegionsList(regionLocationsList)
    
  }, [selectedFilterLocations, selectedFilterRegions, regionLocationsList])

  const selectRegions = (e, region) => {
    let val = e.target.value
    let regions = selectedRegions
   
    var __FOUND = regions.indexOf(val) > -1;
    var result = region.locations.map(function(a) {return a.id;});
    let selected_locations = selectedLocations;
    
    if(e.target.checked){
      if(!__FOUND){
        setSelectedRegions([...selectedRegions, val])
        let final_result = []
        for(let i = 0; i < result?.length; i++){
          if(!selected_locations.includes(result[i])){
            final_result.push(result[i])
          }
        }
        setSelectedLocations([...selected_locations, ...final_result])
      }
    }else{
      if(__FOUND){
        setSelectedRegions(selectedRegions.filter((tag, index) => tag !== val))
        setSelectedLocations(selected_locations.filter(item => !result.includes(item)))
      }
    }
  }

  const selectLocation = (e, region) => {
    let val = parseInt(e.target.value)
    var __FOUND = selectedLocations.includes(val)
    if(e.target.checked){
      if(!__FOUND){
        let newlocs = [...selectedLocations, val]
        setSelectedLocations(newlocs)
        checkRegionVisibility(region, newlocs)
      }
    }else{
      if(__FOUND){
        let newLocs = selectedLocations.filter((tag, index) => tag !== val)  
        setSelectedLocations(newLocs)
        checkRegionVisibility(region, newLocs)  
      }      
    }
  }

  const checkRegionVisibility = (region, locations) =>{
    let location_ids = region.locations.map(function(a) {return a.id;});
    let checker = (arr, target) => target.every(v => arr.includes(v));
    let equal = checker(locations, location_ids)
    let val = region.region?.name

    let regions = selectedRegions
    var __FOUND = regions.indexOf(val) > -1;
    if(equal){
      if(!__FOUND){
        setSelectedRegions([...selectedRegions, val])
      }
    }else{
      if(__FOUND){
        setSelectedRegions(selectedRegions.filter((tag, index) => tag !== val))
      }
    }
  }

	const locationName = () => {
    let locations = locationList
    let selected_locations = selectedLocations
    let locationNames = []
    let ctr = 0
    for(let i = 0; i < locations?.length; i++){
      if(selected_locations.includes(locations[i].id)){
        if(ctr < 2){
          locationNames.push(locations[i].name)
          ctr++
        }
        else{
          break;
        }
      }
    }
    return chekLocationNameCount(locationNames.join(", "))
  }

  const selectAllLocations = () => {
    let regions = regionsList.map(function(a) {return a.region?.name;});
    let locations = searchedLocationsList.map(function(a) {return a.id;});
    if(checkSelectAll()){
      setSelectedRegions(selectedRegions.filter((element) => !regions.includes(element)))
      setSelectedLocations(selectedLocations.filter((element) => !locations.includes(element)))
    }else{
      setSelectedRegions([...new Set([...selectedRegions, ...regions])])
      setSelectedLocations([...new Set([...selectedLocations, ...locations])])
    }
  }

  const processSetSelectedLocations = () => {
    hanldleSelectFilterLocations(selectedRegions, selectedLocations)
    let data = {
      locations: selectedLocations,
      regions: selectedRegions
    }
    let regions = regionLocationsList.map(function(a) {return a.region?.name;});
    let locationState = (regions.every(v => selectedRegions.includes(v)) && regionLocationsList?.length > 0) ? 'all_selected' : selectedLocations?.length > 0 ? 'some_selected' : 'no_selected'
    handleSaveFilters(data, locationState, 'location', user?.id)
    hideItems('location_special')
  }

  const cancelLocationsFilter = () => {
    if(!$('.location_special_items').hasClass("hidden")){
      processOpenLocationsFilter()
    }
    hideItems('location_special')
  }

  const handleLocationSearch = (e) => {
    let val = e.target.value
    if (e.key === ' ') {
      e.preventDefault();
      val += ' ';
    }
    serachLocations(val)
    setSearchValue(val)
  }

  const serachLocations = debounce(val => {
		performSearchLocations(val.trim())
	}, 800);

  const performSearchLocations = (val) => {
    let outputResult = []
    let outputLocations = []
    for (let i = 0; i < regionLocationsList.length; i++){
      
      let region = regionLocationsList[i]
      if(region?.region?.name.toLowerCase().includes(val.toLowerCase())){
        let regionData = {region: region?.region, locations: region?.locations}
        outputResult.push(regionData)
        outputLocations = [...outputLocations, ...region?.locations]
        checkRegionVisibility(regionData, selectedLocations)
      }else{
        let locations = region?.locations?.filter((location) => location.name.toLowerCase().includes(val.toLowerCase()))
        if(locations?.length > 0){
          let regionData = {region: region?.region, locations: locations}
          outputResult.push(regionData)
          outputLocations = [...outputLocations, ...locations]
          checkRegionVisibility(regionData, selectedLocations)
        }
      }
    }
    setRegionsList(outputResult)
    setSearchedLocationsList(outputLocations)
  }

  const processOpenLocationsFilter = () => {
    setSelectedLocations(selectedFilterLocations)
    setSelectedRegions(selectedFilterRegions)
    setSearchValue('')
    setRegionsList(regionLocationsList)
    setSearchedLocationsList(locationList)
  }

  const checkSelectAll = () => {
    let regions = regionsList.map(function(a) {return a.region?.name;});
    return (regions.every(v => selectedRegions.includes(v)) && regionsList?.length > 0)
  }

  useEffect(() => {
    if(selectedLocations?.length > 0 && locationList?.length !== selectedLocations?.length){
      $('.location_special_items_button').addClass('filter-apply-border')
    }else {
      $('.location_special_items_button').removeClass('filter-apply-border')
    }
  }, [selectedLocations])

  return(
    <Fragment>
      <div className="relative filters_box">
        <div className="hidden my-special-div" onClick={() => processOpenLocationsFilter()}></div>
        <Menu as="div" className="relative block text-left">
          <div>
            <Menu.Button className="flex justify-center w-full rounded-xl pl-4 pr-2 py-2.5 bg-gray-custom-50 text-sm text-gray-900 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 location_special_items_button border-2 border-transparent need-border font-medium">
              {selectedLocations?.length === locationList?.length && <p className = "text-gray-900">All Locations</p>
              }
              {selectedLocations?.length < locationList?.length && 
                <React.Fragment>
                  {(selectedLocations?.length === 1 && selectedLocations?.length !== 0) && 
                    <p className = "text-gray-900">{locationName()}</p>
                  }
                  {(selectedLocations?.length > 1 && selectedLocations?.length < locationList?.length) && 
                    <div className = "flex text-gray-900">{locationName()} {(selectedLocations?.length - locationName()?.split(',')?.length) > 0 && <p>+{selectedLocations?.length - locationName()?.split(',')?.length}</p>}</div>
                  }
                </React.Fragment>
              }
              {(selectedLocations?.length === 0  && selectedLocations?.length !== locationList?.length) && <p>Select location(s)</p>}
              <ChevronDownIcon className="ml-auto text-gray-900 h-5 w-5" aria-hidden="true" />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            show={true}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items className="origin-top-right z-30 right-0 w-full rounded-2xl bg-white ring-2 ring-gray-500 focus:outline-none mt-2 hidden location_special_items filter_items absolute">
              <div className="py-2 max-h-60 overflow-y-auto">
                <div className='bg-white rounded-2xl'>
                  <div className='bg-gray-custom-50 rounded-2xl py-1.5 px-3.5 mx-5 my-1'>
                    <div className='flex items-center'>
                      <SearchIcon classNames={'h-5 w-5 text-gray-400'}/>
                      <input type="text" className="ml-1.5 focus:outline-none w-full bg-transparent" placeholder="Search" value={searchValue} onChange={(e) => handleLocationSearch(e)} onKeyDown={handleLocationSearch}></input>
                    </div>
                  </div>
                </div>
                <div className='flex items-center justify-between px-5 py-2 mb-1'>
                  <label className="inline-flex items-center text-sm">
                    <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-2" checked = {checkSelectAll()} onChange = {(e) => selectAllLocations(e)}/>
                    Select all
                  </label>
                  <a onClick={() => cancelLocationsFilter()} className='custom-red text-sm cursor-pointer'>Cancel</a>
                </div>
                <div className=''>
                  {regionsList.map((region, i) => 
                    <div className="py-1" key = {i}>
                      {showRegions &&
                        <Menu.Item>
                          {({ active }) => (
                            <label className="flex px-5 text-gray-900 text-sm mb-2">
                              <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-2 mt-0.5 cursor-pointer" onChange={(e) => selectRegions(e, region)} value={region?.region?.name} checked={selectedRegions.includes(region.region.name)}/>
                              <div className='flex items-center gap-2 cursor-pointer'>{region.region.name}</div>
                            </label> 
                          )}
                        </Menu.Item>
                      }
                      <div className={`${showRegions ? 'ml-5' : ''}`}>
                        {region.locations.map((location, j) =>
                        <Menu.Item key = {j}>
                          {({ active }) => (
                            <label className="flex px-5 text-gray-900 text-sm mb-2">
                              <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-2 mt-0.5 cursor-pointer" checked={selectedLocations.includes(location?.id)} value={location.id} onChange={(e) => selectLocation(e, region)}/>
                              <div className='flex items-center gap-2 cursor-pointer'>{location.name}</div>
                            </label>
                          )}
                        </Menu.Item>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex items-center p-2 border-t">
                <div className="flex justify-center items-center w-full px-3 gap-3">
                  <div className=''>
                    <a className='bg-dark-blue text-white text-sm rounded-md py-2 px-4 font-medium h-9 block cursor-pointer' onClick={(e) => processSetSelectedLocations()}>Apply</a>
                  </div>
                </div>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </Fragment>
  )
}