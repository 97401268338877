function getHeaders(token, subdomain){  
  if (token !== undefined) {
    let obj = {
      'Accept': 'application/vnd.netgym.v3',
      'Content-Type': 'application/json',
      'Authorization': eval(`${token}`),
      'subdomain': localStorage.getItem("businessID")
    }
    return obj
  }  
}

export const updateBusiness = async (user_token, subdomain, business) => {  
  return await fetch(`/api/v3/update_business`,{
    method: "POST",
    headers: getHeaders(user_token, subdomain),
    body: JSON.stringify({ 
      start_date: business.start_date,
      country: business.country,
      default_time_zone: business.default_time_zone,
      escalation_btn: business.escalation_btn,
      invite_all_default: business.invite_all_default,
      activate_past_request: business.activate_past_request,
      sms_notifications: business.sms_notifications,
      mailbox_feature: business.mailbox_feature,
      reports: business.reports,
      can_instructor_modify_locations: business.can_instructor_modify_locations,
      auto_approve_first_accepted: business.auto_approve_first_accepted,
      manager_request_gate: business.manager_request_gate,
      api_synch: business.api_synch,
      crunch_integration: business.crunch_integration,
      mbo_integration: business.mbo_integration,
      abc_integration: business.abc_integration,
      attendance_feature: business.attendance_feature,
      active_filter: business.active_filter,
      departments_filter: business.departments_filter,
      marianatek_integration: business.marianatek_integration,
      second_skill_enabled: business.second_skill_enabled,
      send_auto_mail_to_secondary_instructors: business.send_auto_mail_to_secondary_instructors,
      sms_notifications_restriction: business.sms_notifications_restriction,
      sms_notifications_restriction_days: business.sms_notifications_restriction_days,
      enable_request_sending_time_check: business.enable_request_sending_time_check,
      days_in_advance: business.days_in_advance,
      time_before_class: business.time_before_class,
      time_before_class_type: business.time_before_class_type,
      comments_feature: business.comments_feature,
      active_users_invite: business.active_users_invite,
      turn_off_notifications: business.turn_off_notifications,
      enable_request_sub: business.enable_request_sub,
      mrg_days: business.mrg_days.toString(),
      availability_feature: business.availability_feature,
      business_hours: business.business_hours,
      date_format: business?.date_format,
      resource_hub: business?.resource_hub,
      new_messenger: business?.new_messenger,
      group_chat_feature: business?.group_chat_feature,
      stream_app_id: business?.stream_app_id,
      stream_secret: business?.stream_secret,
      block_availability_feature: business?.block_availability_feature,
      block_subbing_availability: business?.block_subbing_availability,
      block_permanent_availability: business?.block_permanent_availability,
      block_availability_days: business?.block_availability_days,
      show_responsibility_text: business?.show_responsibility_text,
      fisikal_integration: business?.fisikal_integration,
      clubready_integration: business?.clubready_integration,
      requesting_instructor_notifications: business?.requesting_instructor_notifications,
      multi_tenant_enabled: business?.multi_tenant_enabled,
      update_users_stream_token: business?.update_users_stream_token,
      my_schedule_feature: business?.my_schedule_feature,
      default_approval_style: business?.default_approval_style
    })
  });
}

export const exportFuture = async (user_token, subdomain, start, end) => {
  return await fetch(`/api/v3/export_future_sub_requests?start_date=${start}&end_date=${end}`,{
    method: "GET",
    headers: getHeaders(user_token, subdomain)
  });
}