import React, { Fragment, useEffect, useState } from "react";
import { integratedBusiness } from './../../../../subRequests/helpers/helpers';

export default function UrgentClassTextMessageComponent(props) {
  const { newSubRequest, business, user } = props
  const checkIntegratedBusiness = integratedBusiness(business) || false
  const [ subRequest, setSubRequest ] = useState(newSubRequest?.schedules[0])

  useEffect(() => {
    setSubRequest(newSubRequest?.schedules[0])
    if(!checkIntegratedBusiness){
      let schedule = newSubRequest?.secondSkillCheck[0]
      setSubRequest(prevSubRequest => ({
        ...prevSubRequest,
        time_left: schedule?.time_left,
        gfm_alert_show: schedule?.gfm_alert_show
      }))
    }
  }, [newSubRequest])
  
  return(
    <Fragment>
      {(newSubRequest?.schedules?.length == 1 && subRequest?.gfm_alert_show) &&
        <div className="flex text-sm font-medium text-red-500 font-semibold text-center justify-center mb-4">
          {(!user?.cpy_gym && user?.show_responsibility_text) ?
            ((!checkIntegratedBusiness && user?.role_display !== 'Fitness Instructor') ? 
              `Hey, you only have ${subRequest?.time_left} to fill this sub request. Please remember that YOU are responsible to find coverage for your class.`
            :
              `Hey, you only have ${subRequest?.time_left} to fill this sub request. We will notify your manager to help ensure you find a sub on time, but please remember that YOU are responsible to find coverage for your class.`
            )
          :
          (checkIntegratedBusiness && user?.cpy_gym) ?
            `Hi, there is only ${subRequest?.time_left} until this class. In addition to this request, please notify your manager so that a sub can be found in time.`
          :
          (!user?.show_responsibility_text &&
            (user?.role_display == 'Fitness Instructor' ? 
              `Hey, you only have ${subRequest?.time_left} to fill this sub request. We will notify your manager to help ensure you find a sub on time.`
            :
              `Hey, you only have ${subRequest?.time_left} to fill this sub request.`
            )
          )
          }
        </div>
      }
      {(((newSubRequest?.schedules?.length == 1 && !subRequest?.gfm_alert_show) || newSubRequest?.schedules?.length > 1) && !user?.cpy_gym && user?.show_responsibility_text) &&
        <div className="flex text-sm font-medium text-gray-500 font-semibold text-center justify-center mb-4">
          * As a reminder, don't forget that YOU are responsible to find coverage for your class. If you need help, please reach out to your manager.
        </div>
      }
    </Fragment>
  )
}