import React, { Fragment, useState, useEffect } from "react";
import { PushNotificationIcon, TextNotificationIcon, MailNotificationIcon, InfoIcon } from './../../../../subRequestDetails/assets';
import ReactTooltip from "react-tooltip";

export default function InstructorTableComponent(props) {
  const { instructors, instructorsList, instructorMessage, setNewSubRequest, showDisableInstructors } = props
  const [ selectedUser, setSelectedUser ] = useState([])
  const [ usersIds, setUsersIds ] = useState([])
  const [ inviteMoreUsers, setInviteMoreUsers ] = useState(instructors);

  useEffect(() => {
    setInviteMoreUsers(instructors);
    if (instructors) {
      setUsersIds(instructors.map(item => item.id));
    }
  }, [instructors])

  useEffect(() => {
    if (instructorsList) {
      setSelectedUser(instructorsList?.map(item => item.id) || [])
    }
  }, [instructorsList])

  useEffect(() => {
    let insts = instructorsList?.filter(item => selectedUser.includes(item.id))
    handleUpdateInstructor(insts)
  }, [selectedUser])

  const checkAllPeopleSelect = () => {
    let check = (usersIds.every(v => selectedUser.includes(v)) && usersIds?.length !== 0)
    return check
  }

  const handleSelectAllPeople = (e) => {
    if (checkAllPeopleSelect()) {
      setSelectedUser(selectedUser.filter(id => !usersIds.includes(id)));
    } else {
      setSelectedUser([...new Set([...selectedUser, ...usersIds])]);
    }
  }

  const handleSelectPeople = (e) => {
    const userId = parseInt(e.target.value);
    const isChecked = e.target.checked;

    if (isChecked) {
      setSelectedUser(prevSelectedUsers => [...prevSelectedUsers, userId]);
    } else {
      setSelectedUser(prevSelectedUsers => prevSelectedUsers.filter(id => id !== userId));
    }
  };

  const handleUpdateInstructor = (instructors) => {
    setNewSubRequest(prevNewSubRequest => ({
      ...prevNewSubRequest,
      invitedInstructors: instructors
    }))
  }

  return(
    <Fragment>
      <div className="max-h-72 shadow border overflow-auto border-b border-gray-200 rounded-2xl mb-4">
        <table className='min-w-full divide-y divide-gray-200'>
          <thead className='bg-gray-50'>
            <tr>
              <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                <div>
                  <input
                    type="checkbox"
                    className={`w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1 ${showDisableInstructors ? 'cursor-not-allowed opacity-50' : ''}`} 
                    checked={checkAllPeopleSelect() == true ? true : false}
                    value={'all'}
                    onChange={(e) => handleSelectAllPeople(e)}
                    disabled={showDisableInstructors}
                  />
                </div>
              </th>
              <th className={`px-3 py-3 text-left tracking-wider whitespace-nowrap`}>
                <div className='flex items-center gap-1'>
                  <span className='font-semibold text-sm text-gray-900'>Select All</span>
                  <span className='font-semibold text-xs text-gray-500 mt-0.5'>{`${checkAllPeopleSelect() ? 'All Instructors Selected' : ''}`}</span>
                </div>
              </th>
              <th className={`px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider`}>
                <div className='flex items-center'>
                  <span className=''>Matched Skills</span> &nbsp; &nbsp;
                </div>
              </th>
              <th className={`px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider`}>
                <div className='flex items-center'>
                  <span className=''>Location</span> &nbsp; &nbsp;
                </div>
              </th>
              <th className={`px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider`}>
                <div className='flex items-center'>
                  <span className=''>Notification Preference</span> &nbsp; &nbsp;
                </div>
              </th>
            </tr>
          </thead>
          <tbody className='divide-y divide-gray-200'>
            {inviteMoreUsers.map((usr, i) => 
              <tr key={i} className='bg-white relative'>
                <td className='px-3 py-4 text-sm font-medium text-gray-900'>
                  <input
                    type="checkbox"
                    className={`w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1 ${showDisableInstructors ? 'cursor-not-allowed opacity-50' : ''}`}
                    value={usr?.id}
                    checked={selectedUser.includes(usr?.id)}
                    onChange={(e) => handleSelectPeople(e)}
                    disabled={showDisableInstructors}
                  />
                </td>
                <td className='px-3 py-3'>
                  <div className="flex items-center gap-2">
                    <div className="w-6 h-6 rounded-full bg-gray-300">
                      <img className="w-full h-full rounded-full" src={usr?.image ||"/assets/default-profile.jpg"}/>
                    </div>
                    <div className='text-sm font-medium text-gray-900'>
                      {usr.full_name}
                    </div>
                    {!usr?.active &&
                      <div className="text-sm text-gray-600 -ml-1">
                        Inactive
                      </div>
                    }
                  </div>
                </td>
                <td className='px-3 py-3'>
                  <div className='text-sm text-gray-900 font-medium whitespace-nowrap'>
                    {usr?.user_matched_skills}
                  </div>
                </td>
                <td className='px-3 py-3'>
                  <div className='text-sm font-medium text-gray-900'>
                    {usr?.location_name}
                  </div>
                </td>
                <td className='px-3 py-3'>
                  <div className='flex gap-x-2 items-center text-gray-900'>
                    <div data-tip data-for={usr.id.toString()+'user_notification_preference'}>
                      <InfoIcon classNames={"h-3 w-3 shrink-0"}/>
                    </div>
                    <ReactTooltip className='max-w-xs' id={usr.id.toString()+'user_notification_preference'} place="top" effect="solid">
                      {usr?.daily_notifications == 'Immediate' ? 'Receiving every sub request invite immediately at the time it was sent.' : 'Receiving sub request invites once per day as a daily summary at 6pm.'}
                    </ReactTooltip>
                    <div className='text-sm font-medium'>
                      {usr?.daily_notifications}
                    </div>
                    {usr?.push_notifications &&
                      <Fragment>
                        <div className="flex items-center justify-center w-6 h-6 rounded-full border border-gray-300 shrink-0" data-tip data-for={usr.id.toString()+'user_push_notification_preference'}>
                          <PushNotificationIcon classNames={`w-3 h-3 shrink-0`}/>
                        </div>
                        <ReactTooltip className='max-w-xs' id={usr.id.toString()+'user_push_notification_preference'} place="top" effect="solid">
                          {'Push Notifications'}
                        </ReactTooltip>
                      </Fragment>
                    }
                    {usr?.sms_notifications &&
                      <Fragment>
                        <div className="flex items-center justify-center w-6 h-6 rounded-full border border-gray-300 shrink-0" data-tip data-for={usr.id.toString()+'user_text_notification_preference'}>
                          <TextNotificationIcon classNames={`w-3 h-3 shrink-0`}/>
                        </div>
                        <ReactTooltip className='max-w-xs' id={usr.id.toString()+'user_text_notification_preference'} place="top" effect="solid">
                          {'Text Notifications'}
                        </ReactTooltip>
                      </Fragment>
                    }
                    {usr?.email_notifications &&
                      <Fragment>
                        <div className="flex items-center justify-center w-6 h-6 rounded-full border border-gray-300 shrink-0" data-tip data-for={usr.id.toString()+'user_mail_notification_preference'}>
                          <MailNotificationIcon classNames={`w-3 h-3 shrink-0`}/>
                        </div>
                        <ReactTooltip className='max-w-xs' id={usr.id.toString()+'user_mail_notification_preference'} place="top" effect="solid">
                          {'Email Notifications'}
                        </ReactTooltip>
                      </Fragment>
                    }
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Fragment>
  )
}