import React, { Fragment, useState, useEffect } from "react";
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { SearchIcon } from './../../../assets';
import { hideItems, chekLocationNameCount, handleSaveFilters } from './../../../helpers/helpers';
import { debounce } from "lodash";

export default function ClassesFilterComponent(props) {
  const { selectedClasses, handleSelectClasses, allClasses, user } = props
  const [ selectedClassesIds, setSelectedClassesIds ] = useState(selectedClasses || [])
  const [ searchValue, setSearchValue ] = useState('')
  const [ classesList, setClassesList ] = useState(allClasses || [])

  useEffect(() => {
    setClassesList(allClasses)
    setSelectedClassesIds(selectedClasses)
  }, [allClasses, selectedClasses])

  const handleApplyFilter = () => {
    handleSelectClasses(selectedClassesIds)
    let classes = allClasses.map(function(a) {return a?.id;});
    let classState = (classes.every(v => selectedClassesIds.includes(v)) && classes?.length > 0) ? 'all_selected' : selectedClassesIds?.length > 0 ? 'some_selected' : 'no_selected'
    handleSaveFilters(selectedClassesIds, classState, 'class', user?.id)
    hideItems('classespecial')
  }

  const handleClassesSearch = (e) => {
    let val = e.target.value
    if (e.key === ' ') {
      e.preventDefault();
      val += ' ';
    }
    setSearchValue(val)
    searchClasses(val)
  }

  const searchClasses = debounce(val => {
		performSearchClasses(val.trim())
	}, 800);

  const performSearchClasses = (text) => {
    let newClassesList = allClasses.filter(function (cls) {
      return cls?.name?.toLowerCase().includes(text.toLowerCase())
    })
    setClassesList(newClassesList);
  }

  const selectAllClasses = () => {
    let classes = classesList.map(function(a) {return a?.id;});
    if(checkSelectAll()){
      setSelectedClassesIds(selectedClassesIds.filter((element) => !classes.includes(element)))
    }else{
      setSelectedClassesIds([...new Set([...selectedClassesIds, ...classes])])
    }
  }

  const cancelClassesFilter = () => {
    if(!$('.classespecial_items').hasClass("hidden")){
      processFillBackFilter()
    }
    hideItems('classespecial')
  }

  const processSelectClasses = (e, classId) => {
    let clsId = parseInt(classId)
    var __FOUND = selectedClassesIds.includes(clsId)
    if(e.target.checked){
      if(!__FOUND){
        setSelectedClassesIds([...selectedClassesIds, clsId])
      }
    }else{
      if(__FOUND){
        setSelectedClassesIds(selectedClassesIds.filter((id) => id !== clsId))
      }
    }
  }

  const classesNames = () => {
    let names = []
    let classes = allClasses
    let selected_classes = selectedClassesIds
    let ctr = 0
    for(let i = 0; i < classes?.length; i++){
      if(selected_classes.includes(classes[i].id)){
        if(ctr < 2){
          names.push(classes[i].name)
          ctr++
        }
        else{
          break;
        }
      }
    }
    return chekLocationNameCount(names.join(", "))
  }

  const processFillBackFilter = () => {
    setSelectedClassesIds(selectedClasses)
    if(searchValue !== ''){
      performSearchClasses('')
    }
    setSearchValue('')
  }

  const checkSelectAll = () => {
    let classes = classesList.map(function(a) {return a?.id;});
    return (classes.every(v => selectedClassesIds.includes(v)) && classesList?.length > 0)
  }

  useEffect(() => {
    if(selectedClassesIds?.length > 0 && selectedClassesIds?.length !== allClasses?.length){
      $('.classesspecial_items_button').addClass('filter-apply-border')
    }else {
      $('.classesspecial_items_button').removeClass('filter-apply-border')
    }
  }, [selectedClassesIds, allClasses])

  return(
    <Fragment>
      <div className="relative filters_box">
        <div className="hidden my-classes-special-div" onClick={() => processFillBackFilter()}></div>
        <Menu as="div" className="relative block text-left">
          <div>
            <Menu.Button className="flex justify-center w-full rounded-xl pl-3 pr-2 py-2.5 bg-gray-custom-50 text-sm text-gray-900 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 classesspecial_items_button border-2 border-transparent need-border font-medium">
              {selectedClassesIds?.length === allClasses?.length && <p className = "text-gray-900">All Classes</p>
              }
              {selectedClassesIds?.length < allClasses?.length && 
                <React.Fragment>
                  {(selectedClassesIds?.length === 1 && selectedClassesIds?.length !== 0) && 
                    <p className = "text-gray-900">{classesNames()}</p>
                  }
                  {(selectedClassesIds?.length > 1 && selectedClassesIds?.length < allClasses?.length) && 
                    <div className = "flex text-gray-900 items-center">
                      <div className={`text-gray-900 truncate max-wc-160`}>{classesNames()}</div>
                      {(selectedClassesIds?.length - classesNames()?.split(',')?.length) > 0 && <p className="text-xs ml-1">+{selectedClassesIds?.length - classesNames()?.split(',')?.length}</p>}
                    </div>
                  }
                </React.Fragment>
              }
              {selectedClassesIds?.length > allClasses?.length &&
                <div className = "flex text-gray-900 items-center">
                  <div className={`text-gray-900 truncate max-wc-160`}>{classesNames()}</div>
                  {(selectedClassesIds?.length - classesNames()?.split(',')?.length) > 0 && <p className="text-xs">+{selectedClassesIds?.length - classesNames()?.split(',')?.length}</p>}</div>
              }
              {(selectedClassesIds?.length === 0  && selectedClassesIds?.length !== allClasses?.length) && <p>Select Class(es)</p>}
              <ChevronDownIcon className="ml-auto text-gray-900 h-5 w-5" aria-hidden="true" />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            show={true}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items className="origin-top-right z-30 right-0 w-full rounded-2xl bg-white ring-2 ring-gray-500 focus:outline-none mt-2 hidden classesspecial_items filter_items absolute">
              <div className="py-2 max-h-60 overflow-y-auto">
                <div className='bg-white rounded-2xl'>
                  <div className='bg-gray-custom-50 rounded-2xl py-1.5 px-3.5 mx-5 my-1'>
                    <div className='flex items-center'>
                      <SearchIcon classNames={'h-5 w-5 text-gray-400'}/>
                      <input type="text" className="ml-1.5 focus:outline-none w-full bg-transparent" placeholder="Search" value={searchValue} onChange={(e) => handleClassesSearch(e)} onKeyDown={handleClassesSearch}></input>
                    </div>
                  </div>
                </div>
                <div className='flex items-center justify-between px-5 py-2 mb-1'>
                  <label className="inline-flex items-center text-sm">
                    <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-2" checked = {checkSelectAll()} onChange = {() => selectAllClasses()}/>
                    Select all
                  </label>
                  <a onClick={() => cancelClassesFilter()} className='custom-red text-sm cursor-pointer'>Cancel</a>
                </div>
                <div className=''>  
                  {classesList.map((cls, i) => 
                    <div className="py-1" key = {i}>
                      <Menu.Item>
                        {({ active }) => (
                          <label className="flex px-5 text-gray-900 text-sm mb-2">
                            <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-2 mt-0.5 cursor-pointer" onChange={(e) => processSelectClasses(e, cls?.id)} value={cls?.id} checked={selectedClassesIds.includes(cls?.id)}/>
                            <div className='flex items-center gap-2 cursor-pointer'>{cls?.name}</div>
                          </label> 
                        )}
                      </Menu.Item>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex items-center p-2 border-t">
                <div className="flex justify-center items-center w-full px-3 gap-3">
                  <div className=''>
                    <a className='bg-dark-blue text-white text-sm rounded-md py-2 px-4 font-medium h-9 block cursor-pointer' onClick={(e) => handleApplyFilter()}>Apply</a>
                  </div>
                </div>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </Fragment>
  )
}