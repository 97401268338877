import React, { Fragment, useEffect, useState } from "react";
import { ApproveButtonIcon, DeclineButtonIcon, MessageIcon, MaybeIcon } from './../../../../../assets';
import { axiosRequest } from './../../../../../helpers/helpers';
import AutoApproveConfirmationComponent from './autoApproveConfirmationComponent';

export default function AvailableEventsButtonsComponent(props){
  const { event, business, handleAddComment, handleAlert, closeAndReloadData, setLoader } = props
  const [ showAutoApproveModal, setShowAutoApproveModal ] = useState(false)
  const [disableOpacity, setDisableOpacity] = useState(false)
  const [ disableActionButtons, setDisableActionButtons ] = useState({
    accept: event?.user_status == 'Accepted',
    decline: event?.user_status == 'Declined'
   })

   useEffect(() => {
    setDisableActionButtons({
      accept: event?.user_status == 'Accepted',
      decline: event?.user_status == 'Declined'
    })
   }, [event])
  const handlePerformAccept = (action) => {
    if (action == 'maybe') return
    if(action == 'accept' && event?.auto_approve_first_accepted){
      setShowAutoApproveModal(true)
    }else{
      handleProcessUpdate(action)
    }
  }
  const handleProcessUpdate = (action) => {
    let url = `/api/v2/sub_requests/update_status`
    let params = {
      status: action,
      event_id: event?.id,
      inst_id: '',
      gql_event_details: true
    }
    setDisableActionButtons({
      accept: true,
      decline: true
    })
    setDisableOpacity(true)
    setLoader(true)
    axiosRequest(url, 'PATCH', params, 'data').then((result) => {
      setLoader(false)
      if(Object.keys(result).includes('status') && result?.status == 200){
        handleAlert(true, 'Status Updated', 'success');
      }else{
        handleAlert(true, result?.error, 'danger');
      }
      setTimeout(() => {
        handleAlert(false, '', ''); 
        closeAndReloadData(result?.event);
        setDisableActionButtons({
          accept: result?.event?.user_status == 'Accepted',
          decline: result?.event?.user_status == 'Declined'
        })
        setDisableOpacity(false)
      }, 3000)
    })
  }
  return(
    <Fragment>
      {showAutoApproveModal &&
        <AutoApproveConfirmationComponent showAutoApproveModal={showAutoApproveModal} setShowAutoApproveModal={setShowAutoApproveModal} handleProcessUpdate={handleProcessUpdate} />
      }
      <div className='flex gap-1.5 mt-3'>
        <button className={`flex items-center justify-center border border-green-600 rounded-2xl font-semibold w-full tc-11 h-8 gap-0.5 ${event?.user_status == 'Accepted' ? 'text-white bg-green-600' : 'text-green-600'} ${disableActionButtons.accept ? 'cursor-not-allowed' : ''} ${disableOpacity ? 'opacity-50' : ''}`} onClick={() => handlePerformAccept('accept')} disabled={disableActionButtons.accept}>
          <ApproveButtonIcon classNames={'w-3.5 h-3.5'}/>
          Accept
        </button>
        {/* <button className={`flex items-center justify-center border border-purple-600 rounded-2xl font-semibold w-full tc-11 h-8 gap-0.5 ${event?.user_status == 'Maybe' ? 'text-white bg-purple-600' : 'text-purple-600'} ${disableActionButtons ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={() => handlePerformAccept('maybe')}disabled={disableActionButtons}>
          <MaybeIcon classNames={'w-3.5 h-3.5'}/>
          Maybe
        </button> */}
        <button className={`flex items-center justify-center border border-red-600 rounded-2xl font-semibold w-full tc-11 h-8 gap-0.5 ${event?.user_status == 'Declined' ? 'text-white bg-red-600' : 'text-red-600'} ${disableActionButtons.decline ? 'cursor-not-allowed' : ''} ${disableOpacity ? 'opacity-50' : ''}`} onClick={() => handlePerformAccept('decline')} disabled={disableActionButtons.decline}>
          <DeclineButtonIcon classNames={'w-3.5 h-3.5'}/>
          Decline
        </button>
        {business?.comments_feature &&
          // <button className='flex justify-center items-center border border-gray-300 rounded-full min-h-8 min-w-8' onClick={() => handleAddComment()}>
          //   <MessageIcon classNames={'wc-14 hc-14'}/>
          // </button>
          <button className='w-full tc-11 font-semibold flex items-center justify-center border border-gray-300 rounded-2xl h-8 gap-0.5 px-1.5' onClick={() => handleAddComment()}>
            <MessageIcon classNames={'wc-14 hc-14'}/>
            Comment
          </button>
        }
      </div>
    </Fragment>
  )
}