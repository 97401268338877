import React from 'react';
import UserContext from '../userContext';
import Cookies from 'js-cookie';
import { getSudomain, getUserDeatails } from '../../helpers'

class ContextProvider extends React.Component {
  state = {
    user: {},
    fetchedAccounts: false,
    accounts: [],
    notIncludePaths: ['/reset-password', '/confirm-user']
  };

  updateUserData = (newUser) => {
    this.setState({ user: newUser });
  }

  componentDidMount() {
    this.fetchData();
    this.interval = setInterval(this.fetchData, 600000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  fetchData = async () => {
    let subdomain = getSudomain();
    if(subdomain && subdomain !== null && subdomain !== '' && subdomain !== 'home'){
      let currentUserToken = Cookies.get(`currentUser.${subdomain}`)
      if (!currentUserToken || subdomain == 'home') {
        return
      }
      if(this.state.notIncludePaths.includes(location.pathname)) return
      getUserDeatails(Cookies.get(`currentUser.${getSudomain()}`), getSudomain())
      .then(response => response.json())
      .then(result => { 
        if(result.status == 200){
          this.setState({ user: result.user });
        }
      })
    }
  };

  callUserAPI = () => {
    this.fetchData();
  }

  setFetchedAccounts = (data) => {
    this.setState({
      fetchedAccounts: data
    })
  }

  setAccounts = (data) => {
    this.setState({
      accounts: data
    })
  }

  render() {
    return (
      <UserContext.Provider value={
        {
          user: this.state.user,
          updateUserData: this.updateUserData,
          callUserAPI: this.callUserAPI,
          fetchedAccounts: this.state.fetchedAccounts,
          setFetchedAccounts: this.setFetchedAccounts,
          accounts: this.state.accounts,
          setAccounts: this.setAccounts
        }
      }>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

export default ContextProvider;