import React, { Fragment, useState } from "react";
import { PushNotificationIcon, TextNotificationIcon, MailNotificationIcon, InfoIcon } from '../../../../subRequestDetails/assets';
import ReactTooltip from "react-tooltip";

export default function ConfirmInstructorsTableComponent(props) {
  const { newSubRequest } = props;

  return(
    <Fragment>
      <div className="flex flex-col gap-2 w-full mb-4">
        <div className="flex text-base md:text-lg text-black font-semibold">
          Instructors who will be Invited ({newSubRequest?.invitedInstructors?.length})
        </div>
        <div className="max-h-72 shadow border overflow-auto border-b border-gray-200 rounded-2xl">
          <table className='min-w-full divide-y divide-gray-200'>
            <thead className='bg-gray-50'>
              <tr>
                <th className={`px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider`}>
                  <div className='flex items-center'>
                    <span className=''>Instructors</span> &nbsp; &nbsp;
                  </div>
                </th>
                <th className={`px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap`}>
                  <div className='flex items-center'>
                    <span className=''>Matched Skills</span> &nbsp; &nbsp;
                  </div>
                </th>
                <th className={`px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider`}>
                  <div className='flex items-center'>
                    <span className=''>Location</span> &nbsp; &nbsp;
                  </div>
                </th>
                <th className={`px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap`}>
                  <div className='flex items-center'>
                    <span className=''>Notification Preference</span> &nbsp; &nbsp;
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className='divide-y divide-gray-200'>
              {newSubRequest?.invitedInstructors?.map((usr, i) => 
                <tr key={i} className='bg-white relative'>
                  <td className='px-3 py-3'>
                    <div className="flex items-center gap-2">
                      <div className="w-6 h-6 rounded-full bg-gray-300 shrink-0">
                        <img className="w-full h-full rounded-full" src={usr?.image ||"/assets/default-profile.jpg"}/>
                      </div>
                      <div className='text-sm font-medium text-gray-900'>
                        {usr.full_name}
                      </div>
                      {!usr?.active &&
                        <div className="text-sm text-gray-600 -ml-1">
                          Inactive
                        </div>
                      }
                    </div>
                  </td>
                  <td className='px-3 py-3'>
                    <div className='text-sm text-gray-900 font-medium whitespace-nowrap'>
                      {usr?.user_matched_skills}
                    </div>
                  </td>
                  <td className='px-3 py-3'>
                    <div className='text-sm font-medium text-gray-900'>
                      {usr?.location_name}
                    </div>
                  </td>
                  <td className='px-3 py-3'>
                    <div className='flex gap-x-2 items-center text-gray-900'>
                      <div data-tip data-for={usr.id.toString()+'user_notification_preference'}>
                      <InfoIcon classNames={"h-3 w-3"}/>
                      </div>
                      <ReactTooltip className='max-w-xs' id={usr.id.toString()+'user_notification_preference'} place="top" effect="solid">
                        {usr?.daily_notifications == 'Immediate' ? 'Receiving every sub request invite immediately at the time it was sent.' : 'Receiving sub request invites once per day as a daily summary at 6pm.'}
                      </ReactTooltip>
                      <div className='text-sm font-medium'>
                        {usr?.daily_notifications}
                      </div>
                      {usr?.push_notifications &&
                        <Fragment>
                          <div className="flex items-center justify-center w-6 h-6 rounded-full border border-gray-300" data-tip data-for={usr.id.toString()+'user_push_notification_preference'}>
                            <PushNotificationIcon classNames={`w-3 h-3`}/>
                          </div>
                          <ReactTooltip className='max-w-xs' id={usr.id.toString()+'user_push_notification_preference'} place="top" effect="solid">
                            {'Push Notifications'}
                          </ReactTooltip>
                        </Fragment>
                      }
                      {usr?.sms_notifications &&
                        <Fragment>
                          <div className="flex items-center justify-center w-6 h-6 rounded-full border border-gray-300" data-tip data-for={usr.id.toString()+'user_text_notification_preference'}>
                            <TextNotificationIcon classNames={`w-3 h-3`}/>
                          </div>
                          <ReactTooltip className='max-w-xs' id={usr.id.toString()+'user_text_notification_preference'} place="top" effect="solid">
                            {'Text Notifications'}
                          </ReactTooltip>
                        </Fragment>
                      }
                      {usr?.email_notifications &&
                        <Fragment>
                          <div className="flex items-center justify-center w-6 h-6 rounded-full border border-gray-300" data-tip data-for={usr.id.toString()+'user_mail_notification_preference'}>
                            <MailNotificationIcon classNames={`w-3 h-3`}/>
                          </div>
                          <ReactTooltip className='max-w-xs' id={usr.id.toString()+'user_mail_notification_preference'} place="top" effect="solid">
                            {'Mail Notifications'}
                          </ReactTooltip>
                        </Fragment>
                      }
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  )
}