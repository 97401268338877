import React from 'react';
import Cookies from 'js-cookie';
import { getSudomain } from '../../../../helpers'

function getHeaders(token, subdomain){  
  if (token !== undefined) {
    let obj = {
      'Accept': 'application/vnd.netgym.v3',
      'Content-Type': 'application/json',
      'Authorization': eval(`${token}`),
      'subdomain': localStorage.getItem("businessID")
    }
    return obj    
  }  
}

function userToken(){
  return `${Cookies.get(`currentUser.${getSudomain()}`)}`
}

export const axiosGetRequest = async(url) => {
  return await fetch(url, {
    headers: getHeaders(`${userToken()}`, `${getSudomain()}`)
  }).then(      
    response => response.json()
  )
}

export const axiosDeleteRequest = async(url) => {
  return await fetch(url, {
    method: "DELETE",
    headers: getHeaders(`${userToken()}`, `${getSudomain()}`)
  }).then(      
    response => response.json()
  )
}

