import React, { Fragment, Component } from 'react';
import EmailComponent from '../components/emailComponent';
import HeaderComponent from '../components/headerComponent';
import FooterComponent from '../components/footerComponent';
import CardComponent from '../components/cardComponent';
import LoginComponent from '../components/loginComponent';
import AlertPopup from '../../messenger/pages/chatComponents/assets/alertPopup';
import SelectBusinessComponent from '../components/selectBusinessComponent';
import { currentUserEmail } from '../helpers';
import UserContext from '../../userComponents/userContext';
import ForgotPasswordComponent from '../components/forgotPasswordComponent';

export default class SignInLayout extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    let userEmail = currentUserEmail()
    this.state = {
      emailRegex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      loginUser: {
        email: userEmail || '',
        password: ''
      },
      businesses: [],
      showAlert: false,
      alertType: '',
      alertMessage: '',
      pagePath: this.props.path,
      subdomain: this.props.subdomain
    }
  }

  setBusinesses = (businesses) => {
    this.setState({businesses: businesses})
  }

  setLoginUser = (loginUser) => {
    this.setState({loginUser: loginUser})
  }

  setAlert = (showAlert, alertType, alertMessage) => {
    this.setState({
      showAlert: showAlert,
      alertType: alertType,
      alertMessage: alertMessage
    })
  }

  closeAlert = (data) => {
    this.setAlert(data, '', '')
  }

  setPagePath = (data) => {
    this.setState({pagePath: data})
  }

  handleUpdateUser = (data, url) => {
    this.context.updateUserData(data)
    this.context.setFetchedAccounts(false)
    this.context.setAccounts([])
    this.props.updateUserData(data, url)
  }

  render() {
    const { emailRegex, loginUser, showAlert, alertType, alertMessage, pagePath, businesses, subdomain } = this.state
    const { subdomainBusinessImage } = this.props
    
    return (
      <Fragment>
        {showAlert &&
          <AlertPopup alert_type={alertType} alert_message={alertMessage} closeAlert={this.closeAlert}/>
        }
        <div className='h-screen font-open-sans pt-16'>
          <HeaderComponent path={pagePath} subdomainBusinessImage={subdomainBusinessImage}/>
            <div className={`flex items-center justify-center w-full ${pagePath == 'business_select' ? 'h-auto' : pagePath == 'signin' ? 'h-1/2' : 'h-2/3'} bg-gray-custom-50 py-8 px-5 md:px-8`}>
              {pagePath == 'business_select' ? 
                <SelectBusinessComponent businesses={businesses}/> 
              :
                <CardComponent path={pagePath} >
                  {pagePath == 'signin' ?
                    <EmailComponent 
                      emailRegex={emailRegex} 
                      loginUser={loginUser} 
                      setBusinesses={this.setBusinesses} 
                      setLoginUser={this.setLoginUser} 
                      setAlert={this.setAlert}
                      setPagePath={this.setPagePath}
                      subdomain={subdomain}
                    />
                  : pagePath == 'forget-password' ? (
                    <ForgotPasswordComponent 
                      emailRegex={emailRegex}
                      loginUser={loginUser}
                      setAlert={this.setAlert}
                    />
                  ) : (
                    <LoginComponent 
                      emailRegex={emailRegex} 
                      loginUser={loginUser}  
                      setLoginUser={this.setLoginUser} 
                      setAlert={this.setAlert}
                      setPagePath={this.setPagePath}
                      subdomain={subdomain}
                      updateUserData={this.handleUpdateUser}
                    />
                  )}
                </CardComponent>
              }
            </div>
          <FooterComponent path={pagePath} />
        </div>
      </Fragment>
    );
  }
}