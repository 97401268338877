import React, { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import { SearchIcon } from './../../../assets';
import { hideItems, axiosGetRequest, chekLocationNameCount, handleSaveFilters } from './../../../helpers/helpers'
import { debounce } from "lodash";
import InfinitScroll from 'react-infinite-scroll-component'

export default function InstructorFilterComponent(props){
  const { selectedInstructors, handleSelectInstructors, instructorObjects, user } = props
  const [ selectedInst, setSelectedInst ] = useState(selectedInstructors || [])
  const [ allInstObjects, setAllInstObjects ] = useState(instructorObjects || [])
  const [ allInstToShow, setAllInstToShow ] = useState(instructorObjects || [])
  const [ userList, setUserList ] = useState(instructorObjects.slice(0, 30) || [])
  const [ searchValue, setSearchValue ] = useState('')
  const [ userListPage, setUserListPage ] = useState(1)
  
  useEffect(() => {
    setUserList(instructorObjects.slice(0, 30))
    setAllInstObjects(instructorObjects)
    setAllInstToShow(instructorObjects)
    setSelectedInst(selectedInstructors)
    setSearchValue('')
    setUserListPage(1)
  }, [instructorObjects, selectedInstructors])

  const handleInstructorSearch = (e) => {
    let val = e.target.value
    if (e.key === ' ') {
      e.preventDefault();
      val += ' ';
    }
    setSearchValue(val)
    searchInstructors(val)
  }

  const searchInstructors = debounce(val => {
		processSearchInstructors(val.trim())
	}, 1000);

  const processSearchInstructors = (text) => {
    let newUsersList = allInstObjects.filter(function (user) {
      return user?.full_name?.toLowerCase().includes(text.toLowerCase())
    })
    setAllInstToShow(newUsersList);
    $('infiniti-scroll-instructorspecial').scrollTop(0);
    setUserList(newUsersList.slice(0, 30))
  }

  const selectAllInstructors = () => {
    let users = allInstToShow.map(function(a) {return a?.id;});
    if(checkSelectAll()){
      setSelectedInst(selectedInst.filter((element) => !users.includes(element)))
    }else{
      setSelectedInst([...new Set([...selectedInst, ...users])])
    }
  }

  const cancelInstructorsFilter = () => {
    if(!$('.instructorspecial_items').hasClass("hidden")){
      processFillBackFilter()
    }
    hideItems('instructorspecial')
  }

  const selectInstructors = (e, user) => {
    let instId = parseInt(user?.id)
    var __FOUND = selectedInst.includes(instId)
    if(e.target.checked){
      if(!__FOUND){
        setSelectedInst([...selectedInst, instId])
      }
    }else{
      if(__FOUND){
        setSelectedInst(selectedInst.filter((userId) => userId !== instId))
      }
    }
  }

  const handleApplyFilter = (e) => {
    handleSelectInstructors(selectedInst)
    let users = instructorObjects.map(function(a) {return a?.id;});
    let instructorState = (users.every(v => selectedInst.includes(v)) && instructorObjects?.length > 0) ? 'all_selected' : selectedInst?.length > 0 ? 'some_selected' : 'no_selected'
    handleSaveFilters(selectedInst, instructorState, 'instructor', user?.id)
    hideItems('instructorspecial')
  }

  const instructorNames = () => {
    let users = instructorObjects
    let selected_instructors = selectedInst
    let userNames = []
    let ctr = 0
    for(let i = 0; i < users?.length; i++){
      if(selected_instructors.includes(users[i].id)){
        if(ctr < 2){
          userNames.push(users[i].full_name)
          ctr++
        }else{
          break;
        }
      }
    }
    return chekLocationNameCount(userNames.join(", "))
  }

  const processFillBackFilter = () => {
    setSelectedInst(selectedInstructors)
    if(searchValue !== ''){
      processSearchInstructors('')
    }
    setSearchValue('')
  }

  const fetchNextUser = () => {
    const users = allInstToShow
    if(userList?.length < users?.length){
      let newPage = userListPage + 1
      let splitFrom = userListPage*30
      let splitTo = newPage*30 > users?.length ? users?.length : newPage*30
      let newUsers = users.slice(splitFrom, splitTo)
      let newUserList = [...userList, ...newUsers]
      setUserList(newUserList)
      setUserListPage(newPage)
    }
  }

  const checkSelectAll = () => {
    let users = allInstToShow.map(function(a) {return a?.id;});
    return (users.every(v => selectedInst.includes(v)) && allInstToShow?.length > 0)
  }

  useEffect(() => {
    if(selectedInst?.length > 0 && allInstObjects?.length !== selectedInst?.length){
      $('.instructorspecial_items_button').addClass('filter-apply-border')
    }else {
      $('.instructorspecial_items_button').removeClass('filter-apply-border')
    }

  }, [selectedInst, allInstObjects])

  return(
    <Fragment>
      <div className="relative filters_box">
        <div className="hidden my-instructor-special-div" onClick={() => processFillBackFilter()}></div>
        <Menu as="div" className="relative block text-left">
          <div>
            <Menu.Button className="flex justify-center w-full rounded-xl pl-3 pr-2 py-2.5 bg-gray-custom-50 text-sm text-gray-900 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 instructorspecial_items_button border-2 border-transparent need-border font-medium">
              {selectedInst?.length === allInstObjects?.length && <p className = "text-gray-900">All Instructors</p>
              }
              {selectedInst?.length < allInstObjects?.length && 
                <React.Fragment>
                  {(selectedInst?.length === 1 && selectedInst?.length !== 0) && 
                    <p className = "text-gray-900">{instructorNames()}</p>
                  }
                  {(selectedInst?.length > 1 && selectedInst?.length < allInstObjects?.length) && 
                    <div className = "flex text-gray-900 items-center">
                      <div className={`text-gray-900 truncate max-wc-153`}>
                        {instructorNames()}
                      </div>
                      {(selectedInst?.length - instructorNames()?.split(',')?.length) > 0 && <p className="text-xs">+{selectedInst?.length - instructorNames()?.split(',')?.length}</p>}
                    </div>
                  }
                </React.Fragment>
              }
              {selectedInst?.length > allInstObjects?.length &&
                <div className = "flex text-gray-900 items-center">
                  <div className={`text-gray-900 truncate max-wc-153`}>
                    {instructorNames()}
                  </div>
                  {(selectedInst?.length - instructorNames()?.split(',')?.length) > 0 && <p className="text-xs">+{selectedInst?.length - instructorNames()?.split(',')?.length}</p>}
                </div>
              }
              {(selectedInst?.length === 0  && selectedInst?.length !== allInstObjects?.length) && <p>Select Instructor(s)</p>}
              <ChevronDownIcon className="ml-auto text-gray-900 h-5 w-5" aria-hidden="true" />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            show={true}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items className="origin-top-right z-30 right-0 w-full rounded-2xl bg-white ring-2 ring-gray-500 focus:outline-none mt-2 hidden instructorspecial_items filter_items absolute">
              <InfinitScroll
                id="infiniti-scroll-instructorspecial"
                className="py-2"
                dataLength = {userList?.length}
                next = {fetchNextUser}
                height={240}
                hasMore = {true}
              >
                <div className='bg-white rounded-2xl'>
                  <div className='bg-gray-custom-50 rounded-2xl py-1.5 px-3.5 mx-5 my-1'>
                    <div className='flex items-center'>
                      <SearchIcon classNames={'h-5 w-5 text-gray-400'}/>
                      <input type="text" className="ml-1.5 focus:outline-none w-full bg-transparent" placeholder="Search" value={searchValue} onChange={(e) => handleInstructorSearch(e)} onKeyDown={handleInstructorSearch}></input>
                    </div>
                  </div>
                </div>
                <div className='flex items-center justify-between px-5 py-2 mb-1'>
                  <label className="inline-flex items-center text-sm">
                    <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-2" checked = {checkSelectAll()} onChange = {(e) => selectAllInstructors(e)}/>
                    Select all
                  </label>
                  <a onClick={() => cancelInstructorsFilter()} className='custom-red text-sm cursor-pointer'>Cancel</a>
                </div>
                <div className=''>  
                  {userList.map((user, i) => 
                    <div className="py-1" key = {i}>
                      <Menu.Item>
                        {({ active }) => (
                          <label className="flex px-5 text-gray-900 text-sm mb-2">
                            <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-2 mt-0.5 cursor-pointer" onChange={(e) => selectInstructors(e, user)} value={user?.id} checked={selectedInst.includes(user?.id)}/>
                            <div className='flex items-center gap-2 cursor-pointer'>{user?.full_name}</div>
                          </label> 
                        )}
                      </Menu.Item>
                    </div>
                  )}
                </div>
              </InfinitScroll>
              <div className="flex items-center p-2 border-t">
                <div className="flex justify-center items-center w-full px-3 gap-3">
                  <div className=''>
                    <a className='bg-dark-blue text-white text-sm rounded-md py-2 px-4 font-medium h-9 block cursor-pointer' onClick={(e) => handleApplyFilter()}>Apply</a>
                  </div>
                </div>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </Fragment>
  )
}