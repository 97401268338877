import React, { Fragment } from "react";

export default function HeaderComponent(props) {
  const { subdomainBusinessImage } = props

  return(
    <Fragment>
      <div className="fixed w-full z-40 flex-shrink-0 flex h-12 bg-white">
        <div className="max-w-20 mt-4 ml-4">
          <img src={subdomainBusinessImage} />
        </div>
      </div>
    </Fragment>
  )
}