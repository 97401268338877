import React, { Fragment, useState, useEffect, useRef, useCallback } from "react";
import { UserIcon, SearchIcon } from './../../../../assets';
import { debounce } from "lodash";
import { axiosGetRequest } from '../../../../../subRequests/helpers/helpers';
import { getTheUsersHash, customSearchDropDownStyles } from '../../../../helpers';
import Autocomplete from 'react-autocomplete';

export default function RequestingInstructorComponent(props) {
  const { newSubRequest, setNewSubRequest } = props
  const [ requestingInstructorsList, setRequestingInstructorsList ] = useState([])
  const [ page, setPage ] = useState(1)
  const [ totalResults, setTotalResults ] = useState(0)
  const [ allOptions, setAllOptions ] = useState([])
  const [ selectedOption, setSelectedOption ] = useState(newSubRequest?.requestingInstructor?.id !== '' ? {value: newSubRequest?.requestingInstructor?.id, label: newSubRequest?.requestingInstructor?.full_name} : '')
  const [ inputValue, setInputValue ] = useState(newSubRequest?.requestingInstructor?.id !== '' ? newSubRequest?.requestingInstructor?.full_name : '');
  const [ loading, setLoading ] = useState(false);
  const [ menuOpen, setMenuOpen ] = useState(false);
  const [ showNoInstructorFound, setShowNoInstructorFound ] = useState(false);

  useEffect(() => {
    if(newSubRequest?.requestingInstructor?.id !== selectedOption?.value){
      setSelectedOption({value: newSubRequest?.requestingInstructor?.id, label: newSubRequest?.requestingInstructor?.full_name})
    }

  }, [newSubRequest])

  const fetchRequestingInstructors = (page, text) => {
    setLoading(true);
    setShowNoInstructorFound(false);
    axiosGetRequest(`/api/v2/get_requesting_instructors?page=${page}&text=${text}`).then(result => {
      setLoading(false);
      if(result?.status == 200){
        setPage(parseInt(result?.page))
        setRequestingInstructorsList(result?.results)
        setTotalResults(result?.total_count)
        setAllOptions(getTheUsersHash(result?.results) || [])
        setShowNoInstructorFound(result?.results?.length == 0)
      }
    })
  }

  const performSearchRi = (text) => {
    fetchRequestingInstructors(1, text)
  }

  const handleMenuOpen = (e) => {
    $('.requesting-instructor-need-border').addClass('filter-dynamic-border')
  }

  const handleMenuClose = (e) => {
    focusFirstItem();
    setMenuOpen(false);
    $('.requesting-instructor-need-border').removeClass('filter-dynamic-border')
  }

  const serachRi = useCallback(
    debounce(val => {
      performSearchRi(val)
    }, 300),
    []
  );

  const handleOnChange = (e, newValue) => {
    if(newValue.length > 0){
      serachRi(newValue)
    }else{
      setShowNoInstructorFound(false)
      setAllOptions([])
    }
    setMenuOpen(newValue.length > 0)
    setInputValue(newValue)
    if(selectedOption?.label !== newValue.trim()){
      setSelectedOption('')
      handleSelectInstructors('', '');
    }
  }

  const handleOnSelect = (val, item) => {
    setInputValue(val);
    setSelectedOption(item);
    handleSelectInstructors(item.value, item.label);
    setMenuOpen(false);
  }

  const handleSelectInstructors = (id, name) => {
    setNewSubRequest(prevNewSubRequest => ({
      ...prevNewSubRequest,
      requestingInstructor: {id: id, full_name: name}
    }))
  }

  const itemComponent = (item, isHighlighted) => {
    return (
      <div 
        key={item.value} 
        className={`item ${selectedOption?.value == item.value ? 'selected-item' : isHighlighted ? 'item-hover' : ''}`}
      >
        {item.label}
      </div>
    );
  }

  const focusFirstItem = () => {
    if (!$('.item.item-hover').length) $('.item').first().trigger('click')
  }
  
  return (
    <Fragment>
      <div className="flex flex-col gap-2 filter-column mobile-filter-col justify-end">
        <div className="flex text-base md:text-lg text-black font-semibold">
          Requesting Instructor
        </div>
        <div className="relative filters_box sub-reuqest-requesting-instructor-filter">
          <UserIcon classNames={'w-5 h-5 absolute top-3 left-3 z-10'}/>
          <Autocomplete
            value={inputValue}
            items={allOptions}
            getItemValue={item => item.label}
            shouldItemRender={(item, value) => item.label.toLowerCase().indexOf(value?.trim()?.toLowerCase()) > -1}
            inputProps={{
              className: `flex justify-center w-full rounded-2xl pl-8 pr-2 py-2.5 bg-gray-custom-50 text-sm text-gray-900 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 h-11 gap-1 items-center border-2 border-transparent requesting-instructor-need-border class-container`,
              placeholder: "Select User",
              onClick: () => handleMenuOpen(),
              onBlur: () => handleMenuClose()
            }}
            defaultHighlightedIndex={0}
            renderMenu={items => (
              items.length > 0 ? 
                <div className="dropdown">
                  {items}
                </div>
              :
              <div></div>
            )}
            renderItem={(item, isHighlighted, isFocused) => itemComponent(item, isHighlighted, isFocused)}
            onChange={(event, val) => handleOnChange(event, val)}
            onSelect={(event, val) => handleOnSelect(event, val)}
            selectOnBlur={true}
            onBlur={() => {
              setMenuOpen(false);
            }}
            open={menuOpen && allOptions?.length > 0}
          />
          {loading &&
            <div className="custom-dots-loader absolute top-5 right-3 z-10"></div>
          }
          {newSubRequest?.requestingInstructor?.id == '' &&
            <div className='text-red-500 text-xs absolute top-12 left-1'>{(inputValue !== '' && !loading && allOptions?.length == 0 && showNoInstructorFound) ? 'No instructor found' : `* Required`}</div>
          }
        </div>
      </div>
    </Fragment>
  )
}