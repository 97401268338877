import React, { Fragment } from "react";
import PlusIcon from './../assets/plus_icon';
import CalenderView from './../pages/calendarView';
import AllRequestTabs from './../pages/allRequestTabs/allRequestTabs';
import { setTitle } from './../../helpers'
import LoaderComponent from './../pages/components/loaderComponent/loaderComponent'
import CardView from './../pages/cardView';
import CreateSubRequestButtonComponent from './../pages/components/createSubRequestButtonComponent';
import { snakeCase, getName, seprateOutLocations, axiosGetRequest, checkFilterSaved, savePageViewInLocalStorage } from './../helpers/helpers'
import { ListView } from "../pages/listView";
import ApprovalErrorWarningComponent from '../pages/components/approvalErrorWarningComponent';
import { Redirect } from 'react-router-dom';

export default class SubRequestsLayout extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      activeTab: (['all-requests', 'my-requests'].includes(this.props.path)) ? this.handleCheckThePageViewType(this.props.path) : 'calendar',
      loaded: false,
      statusFilter: props?.business?.manager_request_gate ? ['approved', 'cancelled', 'mgr', 'no_show', 'open', 'pending'] : ['approved', 'cancelled', 'no_show', 'open', 'pending'],
      statusState: 'all_selected',
      selectedLocations: [],
      selectedRegions: [],
      locationState: 'no_selected',
      selectedInstructors: [],
      instructorState: 'no_selected',
      selectedClasses: [],
      classState: 'no_selected',
      calendarViewType: 'month',
      apiCallCount: 0,
      allRegionsLocations: [],
      allInstructors: [],
      allClassesData: [],
      dataApiCallCount: 0,
      showApprovalErrorEventsPopup: false,
      approvalErrorEventsCount: 0,
      firstTime: true,
      redirectToOtherPage: false,
      redirectTo: ''
    }
  }

  handleCheckThePageViewType = (path) => {
    const pageView = localStorage.getItem(`${path == 'all-requests' ? 'allRequestsPageView' : "myRequestsPageView"}`);
    if(pageView){
      if(['all-requests'].includes(path) && pageView == 'card'){
        return 'calendar'
      }else{
        return pageView
      }
    }else{
      if(['my-requests'].includes(path)){
        return 'card'
      }
      return 'calendar'
    }
  }

  componentDidMount = () => {
    this.props.setActive(['all-requests'].includes(this.props.path) ? 'Sub Schedule' : 'Sub Board')
    setTitle((['all-requests'].includes(this.props.path)) ? 'all_requests' : 'my_requests')
  }

  checkAndSaveFilter = () => {
    if(checkFilterSaved(this.props.user?.id)){
      let lastFilters = JSON.parse(localStorage.getItem('lastFilters'));
      this.setState({
        statusFilter: lastFilters?.statuses,
        statusState: lastFilters?.statusesState || (lastFilters?.statuses?.length > 0 ? 'all_selected' : 'no_selected'),
        selectedLocations: lastFilters?.locations,
        selectedRegions: lastFilters?.regions,
        locationState: lastFilters?.locationsState || (lastFilters?.locations?.length > 0 ? 'all_selected' : 'no_selected'),
        selectedInstructors: lastFilters?.instructors,
        instructorState: lastFilters?.instructorsState || (lastFilters?.instructors?.length > 0 ? 'all_selected' : 'no_selected'),
        selectedClasses: lastFilters?.classes,
        classState: lastFilters?.classesState || (lastFilters?.classes?.length > 0 ? 'all_selected' : 'no_selected'),
        calendarViewType: (lastFilters?.calendarViewType || 'month')
      })
    }else{
      localStorage.removeItem('lastFilters');
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    if(['all-requests'].includes(this.props.path)){
      if(nextProps.pageLoaded){
        if(this.state.firstTime){
          this.checkCurrentUserPermissions(nextProps.user, nextProps.permissions)
          this.checkAndSaveFilter()
          this.setState({firstTime: false})
        }
      }
    }else{
      if(nextProps.pageLoaded){
        if(this.state.firstTime){
          this.checkCurrentUserPermissions(nextProps.user, nextProps.permissions)
        }
      }
    }
    this.setState({
      loaded: nextProps.pageLoaded
    })
  }

  setActiveTab = (data) => {
    this.setState({
      activeTab: data
    })
    savePageViewInLocalStorage(data, this.props.path)
    this.updateApprovalErrorEventsCount(0)
  }

  handleSaveFilter = (calendarViewType, apiCallCount) => {
    this.setState({
      calendarViewType: calendarViewType,
      apiCallCount: apiCallCount
    })
  }

  handleSaveData = (locations, instructors, classes, apiCallCount) => {
    this.setState({
      allRegionsLocations: locations,
      allInstructors: instructors,
      allClassesData: classes,
      dataApiCallCount: apiCallCount
    })
  }

  hanldeClickOnWarningPopup = (data) => {
    this.setState({
      showApprovalErrorEventsPopup: data
    })
  }

  updateApprovalErrorEventsCount = (data) => {
    this.setState({
      approvalErrorEventsCount: data
    })
  }

  checkCurrentUserPermissions = (user, permissions) => {
    if(this.props.path == 'my-requests'){
      if(user?.role_display == 'Ops' && !permissions?.sub_request?.write){
        this.setState({redirectToOtherPage: true, redirectTo: '/all-requests'})
      }
    }else if(this.props.path == 'all-requests'){
      if(user?.role_display == 'Fitness Instructor'){
        this.setState({redirectToOtherPage: true, redirectTo: '/my-requests'})
      }
    }
  }

  render(){
    const { activeTab, loaded, selectedLocations, statusFilter, selectedRegions, selectedInstructors, selectedClasses, calendarViewType, apiCallCount, allRegionsLocations, allInstructors, allClassesData, dataApiCallCount, statusState, locationState, instructorState, classState, showApprovalErrorEventsPopup, approvalErrorEventsCount, redirectToOtherPage, redirectTo } = this.state
    const { loadingMessage, user, business, path, permissions } = this.props
    
    return (
      <Fragment>
        {redirectToOtherPage &&
          <Redirect to={redirectTo} />
        }
        <div className={`w-full mt-20 md:mt-8 min-h-screen font-open-sans bg-gray-custom-50`}>
          <div className="px-8">
            <header>
              <h1 className="text-2xl font-semibold mb-5 leading-tight text-gray-900">{['my-requests'].includes(path) ? 'My ' : 'All '}Requests</h1>
            </header>
          </div>
          {!loaded &&
            <LoaderComponent loadingMessage={loadingMessage}/>
          }
          {loaded &&
            <Fragment>
              <div className='w-full px-8 md:flex items-center justify-between space-y-3 md:space-y-0 mt-5'>
                <CreateSubRequestButtonComponent user={user} permissions={permissions} business={business}/>
                {approvalErrorEventsCount > 0 && 
                  <ApprovalErrorWarningComponent setShowApprovalErrorEventsPopup={this.hanldeClickOnWarningPopup} approvalErrorEventsCount={approvalErrorEventsCount}/>
                }
                <AllRequestTabs activeTab={activeTab} setActiveTab={this.setActiveTab} path={path}/>
              </div>
              {activeTab === 'calendar' && 
                <CalenderView 
                loadingMessage={loadingMessage} 
                business={business} 
                user={user} 
                activeTab={activeTab} 
                path={path} 
                selectedLocationsFilter={selectedLocations} 
                statusesFilter={statusFilter} 
                selectedRegionsFilter={selectedRegions} 
                selectedInstructorsFilter={selectedInstructors} 
                selectedClassesFilter={selectedClasses} 
                calendarViewTypeFilter={calendarViewType} 
                totalApiCallCount={apiCallCount} 
                allRegionsLocations={allRegionsLocations} 
                allInstructors={allInstructors} 
                allClassesData={allClassesData} 
                handleSaveData={this.handleSaveData} 
                dataApiCallCount={dataApiCallCount}
                statusState={statusState}
                locationState={locationState}
                instructorState={instructorState}
                classState={classState}
                handleSaveFilter={this.handleSaveFilter}
                hanldeClickOnWarningPopup={this.hanldeClickOnWarningPopup}
                showApprovalErrorEventsPopup={showApprovalErrorEventsPopup}
                updateApprovalErrorEventsCount={this.updateApprovalErrorEventsCount}
              />
              }
              {(activeTab === 'card') && 
                <CardView loadingMessage={loadingMessage} business={business} user={user} activeTab={activeTab} path={path}/>
              }
              {(activeTab === 'list') &&
                <ListView 
                loadingMessage={loadingMessage} 
                business={business} 
                user={user} 
                activeTab={activeTab} 
                path={path} 
                handleSaveFilter={this.handleSaveFilter} 
                selectedLocationsFilter={selectedLocations} 
                statusesFilter={statusFilter} 
                selectedRegionsFilter={selectedRegions} 
                selectedInstructorsFilter={selectedInstructors} 
                selectedClassesFilter={selectedClasses} 
                calendarViewTypeFilter={calendarViewType} 
                totalApiCallCount={apiCallCount} 
                allRegionsLocations={allRegionsLocations} 
                allInstructors={allInstructors} 
                allClassesData={allClassesData} 
                handleSaveData={this.handleSaveData} 
                dataApiCallCount={dataApiCallCount}
                statusState={statusState}
                locationState={locationState}
                instructorState={instructorState}
                classState={classState}
              />
              }
            </Fragment>
          }
        </div>
      </Fragment>
    )
  }
}