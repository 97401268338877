import React, { Fragment, useState } from "react";
import FilterIcon from "./../../../../assets/filterIcon";
import EditIcon from "./../../../../assets/editIcon";
import CardComponent from './../cardComponent';
import { oldControllerAxiosGetRequest } from './../../../../helpers/helpers';
import EmptyCardsMessageComponent from '../emptyCardsMessageComponent';
import Pagination from "react-js-pagination";

export default function AvailableEventsComponent(props) {
  const { availableEvents, user, business, handleAlert, processGetEventData, path, getEventDataWithoutLoad, userHideDeclinedRequests, handleUpdateEvents, handleDeleteEvent, disabledSubBoardPage, page, pageSize, availableEventsTotalCount, handleChangePage, loadingMessage, paginationLoader } = props;
  const [ hideDeclineRequest, setHideDeclineRequest ] = useState(userHideDeclinedRequests)
  const [ loader, setLoader ] = useState(false)

  const handleHideDeclinedRequests = (e) => {
    setHideDeclineRequest(e.target.checked)
    processUpdateHideDeclinedValue(e.target.checked);
  }

  const processUpdateHideDeclinedValue = (val) => {
    let url = `/api/businesses/update_hide_request_settings?val=${val}`
    oldControllerAxiosGetRequest(url).then((result) => {
      if (Object.keys(result).includes('updated') && result.updated) {
        getEventDataWithoutLoad()
      }
    })
  }

  const handlePageChange = (page) => {
    handleChangePage(page)
  }

  return (
    <Fragment>
      <div className="w-full mb-7 lg:mb-0 relative">
        {(paginationLoader || loader) &&
        <div className="bg-gray-400 bg-opacity-10 absolute bottom-0 z-10 w-full h-full">
          <div className="msg-loading flex flex-col items-center w-full mx-auto md:ml-20 ml-0 left-0 right-0 fixed top-1/2 z-10">
            <img className = "w-9" src="/assets/business/loading.gif" />
          </div>
        </div>
        }
        <div className="bg-white shadow-md rounded-lg px-3">
          <div className="flex flex-wrap items-center justify-between">
            <div className="flex text-black text-sm font-medium py-4">
              Available{" "}
              {!disabledSubBoardPage && availableEvents?.length > 0 ? `(${availableEventsTotalCount})` : ""}
            </div>
            {!disabledSubBoardPage &&
              <label className="inline-flex items-center text-gray-500 text-sm gap-1">
                <input type="checkbox" className="w-3.5 h-3.5 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300" checked = {hideDeclineRequest} onChange = {(e) => handleHideDeclinedRequests(e)}/>
                Hide Declined Requests
              </label>
            }
          </div>
        </div>
        <div className="mt-3">
          {!disabledSubBoardPage &&
            <Fragment>
              {availableEvents?.length > 0 ?
                <Fragment>
                  {availableEvents?.map((event, i) => (
                    <div key={i}>
                      <CardComponent event={event} listType={"Available"} user={user} business={business} handleAlert={handleAlert} processGetEventData={getEventDataWithoutLoad} path={path} handleUpdateEvents={handleUpdateEvents} handleDeleteEvent={handleDeleteEvent} setLoader={setLoader} />
                    </div>
                  ))}
                </Fragment>
              :
                <EmptyCardsMessageComponent listType={"Available"}/>
              }
            </Fragment>
          }
        </div>
        {['my-requests'].includes(path) && availableEventsTotalCount > pageSize &&
          <div className="flex justify-center w-full flex-wrap mt-4 pb-2">
            <div className="custom-pagination order-first sm:order-last mb-3 sm:mb-0 mr-7 list-view-pagination">
              <Pagination
                activePage={page}
                itemsCountPerPage={pageSize}
                totalItemsCount={availableEventsTotalCount}
                pageRangeDisplayed={9}
                onChange={handlePageChange}
                itemClassPrev='previous'
                itemClassNext='next'
                itemClassFirst='first'
                itemClassLast='last'
                hideDisabled={true}
                activeClass={'pagination-active-tab'}
              />
            </div>
          </div>
        }
      </div>
    </Fragment>
  );
}