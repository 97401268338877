import React, { Fragment, useState } from 'react';
import InstructorActionButtonsComponent from './../instructorActionButtonsComponent';
import { BellIcon } from './../../../../subRequests/assets';
import InstructorDetailComponent from './../../../../subRequests/pages/components/cardViewComponents/cardComponent/instructorDetailComponent';

export default function UsersListCardComponent(props){
  const { sub, redirectToUrl, handleAlert, subTimestamp, cardType, eventData } = props
  const [ userToShowDetails, setUserToShowDetails ] = useState({})
  const [ showInstructorDetail, setShowInstructorDetail ] = useState(false)

  const handleShowInstructorDetails = (user) => {
    setUserToShowDetails(user)
    setShowInstructorDetail(true)
  }

  const handleCloseInstructorDetails = () => {
    setShowInstructorDetail(false)
    setUserToShowDetails({})
  }

  return(
    <Fragment>
      {showInstructorDetail &&
        <InstructorDetailComponent showInstructorDetail={showInstructorDetail} detailInstructor={userToShowDetails} close={handleCloseInstructorDetails} handleAlert={handleAlert}/>
      }
      <div className="flex gap-2">
        <div className="hidden lg:flex w-12 mt-1">
          <img
            src={sub?.image || '/assets/default-profile.jpg'}
            className="h-12 w-12 rounded-full"
          />
        </div>
        <div className="flex lg:hidden w-4">
          <img
            src={sub?.image || '/assets/default-profile.jpg'}
            className="h-4 w-4 rounded-full"
          />
        </div>
        <div className="flex items-center">
          <div>
            <div className="flex items-center gap-1">
              <button className="hidden lg:flex text-md font-bold ml-2 underline" onClick={() => handleShowInstructorDetails(sub)}>{sub?.full_name}</button>
              <button className="text-xs underline flex lg:hidden font-semibold" onClick={() => handleShowInstructorDetails(sub)}>{sub?.full_name}</button>
            </div>
            {['Approved'].includes(cardType) && eventData?.user_is_manager && sub?.accepted_at && sub?.accepted_at !== '' &&
              <div className="flex items-center gap-2 pt-1 lg:pt-2 ml-0 lg:ml-2 text-xs font-medium text-neutral-600">
                {`Accepted on ${sub?.accepted_at}`}
              </div>
            }
            {['Accepted', 'Approved', 'Declined'].includes(cardType) && eventData?.user_is_manager &&
              <div className="flex items-center gap-2 py-1 lg:py-2 ml-0 lg:ml-2 text-xs font-medium text-neutral-600">
                {cardType == 'Accepted' ?
                  (subTimestamp !== '' && `Accepted on ${subTimestamp}`)
                :
                cardType == 'Approved' ?
                  (eventData?.approved_by !== '' ? `${(sub?.accepted_at && sub?.accepted_at !== '') ? 'Approved' : 'Assigned' } by: ${eventData?.approved_by} on ${subTimestamp}` : '')
                :
                cardType == 'Declined' &&
                  `Declined on ${subTimestamp}`
                }
              </div>
            }
          </div>
        </div>
      </div>
    </Fragment>
  )
}