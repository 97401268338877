import React, { useEffect, useState } from "react";
import { Avatar, useChannelStateContext, useChatContext } from "stream-chat-react";
import { CustomDropDownButton } from './assets/customDropDownButton'
import AudienceModal from './assets/audienceModal'
import AlertPopup from './assets/alertPopup'
import { Link, Redirect } from 'react-router-dom';
import XCircleIcon from './assets/xCircleIcon'
import ChannelNotificationTimeStamp from './channelPreviewTimeStamp/channelNotificationTimeStamp'
import CustomPopup from './assets/customPopup'

export default function CustomChannelInfo ( props ) {
	const { setInfoComponentOff, locationTags, skillTags, allAudienceList, homeClubTags, showDeleteButton, showEditButton } = props
	const { channel } = useChannelStateContext();
	const { client, setActiveChannel } = useChatContext();
	const [ showPeopleModal, setShowPeopleModal ] = useState(false)
	const [ audienceList, setAudienceList ] = useState(allAudienceList)
	const members = Object.values(channel.state.members).filter(
		({ user }) => user.id !== client.userID
	);
	members.sort((a, b) => a.user.name.localeCompare(b.user.name, 'es', {sensitivity: 'base'}))
	const [ showAlertPopup, setShowAlertPopup ] = useState(false)
	const [ alertType, setAlertType ] = useState('success')
	const [ alertMessage, setAlertMessage ] = useState('')
	const [ redirect, setRedirect ] = useState(false)
	const [ redirectTo, setRedirectTo ] = useState('')

	const audience = () => {
		let members = channel.state.members
		let membersIds = Object.keys(members)
		let users = membersIds.map(usr => {return members[usr].user})
		users.sort((a, b) => a.name.localeCompare(b.name, 'es', {sensitivity: 'base'}))
		setAudienceList(users)
	}

	useEffect(() => {
    if(channel){
      // audience()
			// checkMutedDuration()
    }
  }, [channel])

	const checkMutedDuration = () => {
		const muteDurationHash = channel.muteStatus();
		if(Object.keys(muteDurationHash).length > 0){
			if(muteDurationHash?.muted && muteDurationHash?.expiresAt !== null){
				let showTime = ChannelNotificationTimeStamp(muteDurationHash?.expiresAt)
				// setMutedDuration(showTime)
			}else{
				// setMutedDuration('')
			}
		}
	}

	const setInfoComponent = (data) => {
		setInfoComponentOff(data)
	}

	const handleClickOnAudince = (data) => {
		setShowPeopleModal(data)
	}

	const closeAudienceModal = (data) => {
		setShowPeopleModal(data)
	}

	const renderDmName = (members) => {

    if (!members.length || members.length === 1) {
      return(<span>{members[0]?.user.name || members[0]?.user.id || defaultName}</span>)
    }

    let names = members.map((usr) => {return usr.user.name})
    let len = 2
    let shortName = []
    for(let i = 0; i < names?.length; i++){
      if(i < len){
        shortName.push(names[i])
      }else{
        // shortName.push(`${names?.length - len}${names?.length > 99 ? '+' : ''} ${(names?.length - len) > 1 ?'others' : 'other'}`)
				shortName.push(`${channel.data?.member_count - 3} ${(channel.data?.member_count - 3) > 1 ?'others' : 'other'}`)
        break
      }
    }
    let groupName = shortName.join(', ') 
    
    return(<span>{groupName}</span>)
  }

	const handleCloseAlertPopup = (data) =>{
		setShowAlertPopup(false)
	}

	const handleShowAlertPopup = (show, alertType, alertMessage) =>{
		setShowAlertPopup(show)
		setAlertType(alertType)
		setAlertMessage(alertMessage)
	}

	const changeChannel = async (data) => {
		setShowPeopleModal(false)
		setRedirectTo(`/messenger/chat-room/${data}`)
		setRedirect(true)
	}

	return(
		<React.Fragment>
			{redirect && (
				<Redirect push to={`${redirectTo}`} />
			)}
			<div>
				<div className="py-6 border-b head-92">
					<div className="flex justify-between items-center px-5">
						<div className="text-lg font-semibold group-title">{channel?.type === 'team' ? 'Group Information' : (!members.length || members.length === 1 ? 'User Information' : 'Group Information')}</div>
						<div className="flex gap-5 items-center">
							{(showEditButton) &&
								<Link to={`/messenger/edit-chat-room/${channel?.id}`} className={`bg-dark-blue flex items-center justify-center rounded-2xl py-2.5 gap-1.5 w-24`}>
									<div className='text-white text-sm font-medium'>Edit</div>
								</Link>
							}
							<div className="cursor-pointer" onClick={() => setInfoComponent(false)}>
								<XCircleIcon classNames={'w-8 h-8'} />
							</div>
						</div>
							
					</div>
				</div>
				<div className="px-8 pt-2 group-info-section">
					<div className="flex gap-1 items-center font-semibold py-4"> 
						{channel?.type === 'team' ?
							<React.Fragment>  
								<Avatar 
									image={channel?.data.image || undefined}
									name={channel?.data.name || channel?.data.id}
									size={50}
								/>
								<div>{channel?.data.name || 'Channel'}</div>
							</React.Fragment>
						:
							<React.Fragment>
								{!members.length || members.length === 1 ?
										<Avatar 
											image={members[0]?.user.image || undefined}
											name={members[0]?.user.name || members[0]?.user.id}
											size={50}
										/>
									:
									(channel?.data.image && channel?.data.image !== '' ?
											<Avatar 
												image={channel?.data.image || members[0]?.user.image || undefined}
												name={members[0]?.user.name || members[0]?.user.id}
												size={50}
											/>
										:
											<Avatar 
												image={members[0]?.user.image || undefined}
												name={members[0]?.user.name || members[0]?.user.id}
												size={50}
											/>
									)
								}
								<div className="text-base font-semibold">{renderDmName(members)}</div>
							</React.Fragment>
						}
					</div>
					{channel?.type === 'team' &&
						<React.Fragment>
							<div className="flex border-b py-4">
								<div className="text-md font-medium text-gray-400 w-72 flex-shrink-0">
									Audience
								</div>
								<div className="flex items-center gap-2 flex-wrap">
									{homeClubTags.map((loc, i) => 
										<div className="red-tag text-xs px-2.5 py-0.5 rounded-xl font-medium" key={i}>
											{loc.name}
										</div>
									)}
									{locationTags.map((loc, i) => 
										<div className="red-tag text-xs px-2.5 py-0.5 rounded-xl font-medium" key={i}>
											{loc.name}
										</div>
									)}
									{skillTags.map((skill, i) => 
										<div className="bg-purple-100 text-purple-800 text-xs px-2.5 py-0.5 rounded-xl font-medium" key={i}>
											{skill.name}
										</div>
									)}
								</div>
							</div>
							<div className="flex items-center border-b py-6">
								<div className="text-md text-gray-400 font-medium w-72 flex-shrink-0">
									Who can post in the group
								</div>
								<div className="text-sm text-black font-semibold">
									{channel.data?.acknowledged_roles && channel.data?.acknowledged_roles.join(', ')}
								</div>
							</div>
							{channel.data?.description !== '' &&
								<div className="flex border-b py-6 items-center">
									<div className="text-md text-gray-400 font-medium w-72 flex-shrink-0">
										Description
									</div>
									<div className="text-sm text-black font-semibold max-h-40 overflow-y-auto word-break">
										{channel.data?.description || 'NA'}
									</div>
								</div>
							}
							<div className="flex border-b py-6 hidden">
								<div className="w-52"></div>
								<div className="flex gap-2">
									<div className="">
										<button className="text-blue-500" onClick={() => {}}>Archive</button>
									</div>
								</div>
							</div>
						</React.Fragment>
					}
					<div className="mb-3 flex border-b py-6">
						<div className="flex flex-wrap gap-2 items-center">
							{(channel?.type === 'team' ||  members.length > 1) &&	
								<CustomDropDownButton name={"Audience"} value={audienceList?.length || channel.data?.member_count} open={showPeopleModal} onClickOnButton={handleClickOnAudince} classNames={''} extraValue={''}/>
							}
						</div>
					</div>
				</div>
			</div>
			{showPeopleModal && 
				<CustomPopup show={showPeopleModal} close={closeAudienceModal} title={''}>
					<AudienceModal audienceList = {audienceList} handleShowAlertPopup={handleShowAlertPopup} changeChannel={changeChannel}/>
				</CustomPopup>
			}
			{showAlertPopup && <AlertPopup alert_type = {alertType} alert_message = {alertMessage} closeAlert = {handleCloseAlertPopup}/>}
		</React.Fragment>
	)
}