import React, { Fragment } from "react";
import StatusFilterComponent from '../statusFilterComponent/statusFilterComponent';
import LocationFilterComponent from './../locationFilterComponent/locationFilterComponent';
import EditIcon from './../../../assets/editIcon'
import CalenderViewFilter from './../calenderViewFilter/calenderViewFilter'
import MonthSelectComponent from './../monthSelectComponent'
import CalendarViewTabs from './../myRequestsCalendarViewTabs';
import FilterIcon from './../../../assets/filterIcon'
import WeekTypeRangeSelectorComponent from './../calendarRangeTypeSelectorComponents/weekTypeRangeSelectorComponent';
import InstructorFilterComponent from './../instructorFilterComponent';
import ClassesFilterComponent from './../classesFilterComponent';
import DownloadComponent from './../downloadComponent';
import YearTypeRangeSelectorComponent from './../calendarRangeTypeSelectorComponents/yearTypeRangeSelectorComponent';
import ShowAllEventsButtonComponent from '../showAllEventsButtonComponent';
import ShowPastEventsButtonComponent from '../showPastEventsButtonComponent';

export default function FiltersComponent(props) {
  const { selectedStatus, setSelectedStatus, business, approvedCount, cancelledCount, managerCount, noShowCount, openCount, pendingCount, regionLocationsList, selectedLocations, hanldleSelectLocations, selectedRegions, selectedMonth, setSelectedMonth, myRequestsTab, handleMyRequestsTab, sentCount, availableCount, path, calendarViewType, handleCalendarViewType, currentDate, selectedWeek, handleWeekChange, selectedInstructors, handleSelectInstructors, instructorObjects, handleSelectClasses, selectedClasses, allClasses, activeTab, selectedYear, showAllEvents, handleSetShowAllEvents, showRegions, hidePastEvents, handleSetHidePastEvents, user } = props

  const handleSetSelectedStatus = (data) => {
    setSelectedStatus(data)
  }

  const handleChangeMonth = (data) => {
    setSelectedMonth(data)
  }

  const handleCalendarViewChange = (data) => {
    handleCalendarViewType(data)
  }

  const handleChangeWeek = (data) => {
    handleWeekChange(data)
  }

  return (
    <Fragment>
      <div className="py-5 px-8 bg-white">
        <div className={`flex ${(['my-requests'].includes(path)) ? 'justify-between' : 'justify-center'} flex-wrap gap-3`}>
          <div className="flex items-center gap-3 month-select-filter flex-wrap">
            {calendarViewType == 'month' &&
              <MonthSelectComponent selectedMonth={selectedMonth} handleChangeMonth={handleChangeMonth} />
            }
            {['week', 'day'].includes(calendarViewType) &&
              <WeekTypeRangeSelectorComponent handleChangeWeek={handleChangeWeek} selectedWeek={selectedWeek} calendarViewType={calendarViewType} currentDate={currentDate}/>
            }
            {calendarViewType == 'year' &&
              <YearTypeRangeSelectorComponent selectedYear={selectedYear} handleChangeYear={handleChangeWeek}/>
            }
          </div>
          {(['my-requests'].includes(path)) &&
            <div className="flex items-center gap-3 flex-wrap">
              <CalendarViewTabs myRequestsTab={myRequestsTab} handleMyRequestsTab={handleMyRequestsTab} sentCount={sentCount} availableCount={availableCount} approvedCount={approvedCount}/>
            </div>
          }
          {(['my-requests'].includes(path)) &&
            <div className="flex items-center gap-3 flex-wrap">
              <div className="w-c-26">
                <CalenderViewFilter 
                  calendarViewType={calendarViewType} 
                  handleCalendarViewChange={handleCalendarViewChange}
                  path={path}
                  user={user}
                />
              </div>
              {activeTab == 'calendar' &&
                <ShowAllEventsButtonComponent 
                  showAllEvents={showAllEvents} 
                  handleSetShowAllEvents={handleSetShowAllEvents}
                />
              }
              {activeTab == 'list' &&
                <ShowPastEventsButtonComponent 
                  hidePastEvents={hidePastEvents} 
                  handleSetHidePastEvents={handleSetHidePastEvents}
                />
              }
            </div>
          }
        </div>
        {(['all-requests'].includes(path)) &&
          <div className="flex justify-center items-center flex-wrap gap-3 mt-3">
            <div className="w-56">
              <StatusFilterComponent selectedStatus={selectedStatus} setSelectedStatus={handleSetSelectedStatus} business={business} approvedCount={approvedCount} cancelledCount={cancelledCount} managerCount={managerCount} noShowCount={noShowCount} openCount={openCount} pendingCount={pendingCount} user={user}/>
            </div>
            <div className="w-56">
              <LocationFilterComponent regionLocationsList={regionLocationsList} selectedFilterLocations={selectedLocations} hanldleSelectFilterLocations={hanldleSelectLocations} selectedFilterRegions={selectedRegions} showRegions={showRegions} user={user}/>
            </div>
            <div className="w-56">
              <InstructorFilterComponent selectedInstructors={selectedInstructors} handleSelectInstructors={handleSelectInstructors} instructorObjects={instructorObjects} user={user}/>
            </div>
            <div className="w-56">
              <ClassesFilterComponent handleSelectClasses={handleSelectClasses} selectedClasses={selectedClasses} allClasses={allClasses} user={user}/>
            </div>
            <div className="w-c-26">
              <CalenderViewFilter 
                calendarViewType={calendarViewType} 
                handleCalendarViewChange={handleCalendarViewChange}
                path={path}
                user={user}
              />
            </div>
            {activeTab == 'calendar' &&
              <ShowAllEventsButtonComponent 
                showAllEvents={showAllEvents} 
                handleSetShowAllEvents={handleSetShowAllEvents}
              />
            }
            {activeTab == 'list' &&
              <ShowPastEventsButtonComponent 
                hidePastEvents={hidePastEvents} 
                handleSetHidePastEvents={handleSetHidePastEvents}
              />
            }
            <DownloadComponent business={business}/>
          </div>
        } 
      </div>
    </Fragment>
  )
}